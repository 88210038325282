import { useState } from "react";
import { toast } from "react-toastify";
import { fetchData } from "src/async/fetch";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { apiUrl } from "src/config/host";

const WorkspaceAddModal = ({ isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const [name, setName] = useState("");

  const createWorkspace = async () => {
    try {
      const res = await fetchData("POST", `${apiUrl}/workspaces/add`, { name, workspace_type: "DASHBOARD_EMBED" });
      const data = await res.json();

      if (data.status !== 200) {
        toast.error(data.message);
        return;
      }

      onSuccess();
      setName("");
      toast.success(data.message);
    } catch (error) {
      console.dir("error", error);
      toast.error(error.message);
    }
  };

  return (
    <Modal
      title="Workspace"
      secondaryTitle="Create"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={createWorkspace}
      defaultOptions={{
        onSuccessButtonText: "Save",
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <Input
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default WorkspaceAddModal;
