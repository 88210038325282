const SiteColorItem = ({ color = "", onChange, name }) => {
  return (
    <div className="py-0 relative">
      <div className="text-black font-semibold text-xs mb-1 leading-none uppercase">{name}</div>
      <div className="flex items-center pr-4">
        <div className="w-8 h-6">
          <input className="uppercase" id={`color-picker-${name}`} type="color" value={color} onChange={(e) => onChange("#" + e.target.value.split("#")[1]?.toUpperCase())} />
        </div>
          #<input value={color.split("#")[1]?.toUpperCase()} className="w-full overflow-x-hidden" onChange={(e) => onChange("#" + e.target.value?.toUpperCase())}></input>
      </div>
    </div>
  );
};

export default SiteColorItem;
