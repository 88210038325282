import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { manageWorkspaceSettings } from "src/actions/workspace";
import CustomNavigation from "src/assets/svgs/custom_navigation.svg";
import LeftNavigation from "src/assets/svgs/left_navigation.svg";
import TopNavigation from "src/assets/svgs/top_navigation.svg";
import { apiRequest } from "src/async/apiUtils";
import Section from "src/components/Shared/Containers/Section";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import { H3 } from "src/components/Shared/Text/Headers";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";
import LandingPageSelector from "./LandingPageSelector";

const WorkspaceLayoutStyles = ({ ...props }) => {
  const { id } = useParams();
  const [layoutType, setLayoutType] = useState("");
  const [layoutLandingPageId, setLayoutLandingPageId] = useState(null);

  const options = [
    {
      key: (
        <div className="h-7 flex items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor"
            src={LeftNavigation}
            alt="left navigation"
          />
          <p className="text-xl">Vertical</p>
        </div>
      ),
      value: "LEFT_NAVIGATION",
    },
    {
      key: (
        <div className="h-7 flex items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor hover:text-highlightColor"
            src={TopNavigation}
            alt="top navigation"
          />
          <p className="text-xl">Horizontal</p>
        </div>
      ),
      value: "TOP_NAVIGATION",
    },
    {
      key: (
        <div className="h-7 flex items-center gap-x-5">
          <img
            className="h-7 w-7 hover:stroke-highlightColor"
            src={CustomNavigation}
            alt="custom navigation"
          />
          <p className="text-xl">Custom</p>
        </div>
      ),
      value: "CUSTOM_NAVIGATION",
    },
  ];

  const {
    response: { data: workspace },
    refreshData: refreshWorkspace,
  } = useFetch(`/workspaces/${id}`);

  useEffect(() => {
    const setUser = async () => {
      await props.getAuthorizedUser();
    };
    setUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspace?.layout_type) setLayoutType(workspace?.layout_type);
  }, [workspace?.layout_type]);

  useEffect(() => {
    setLayoutLandingPageId(workspace.layout_landing_page_id);
  }, [workspace.layout_landing_page_id]);

  const handleStylesUpdate = async (body) => {
    try {
      if (body.image_favicon !== workspace.image_favicon) {
        await apiRequest("DELETE", `/workspaces/${body._id}/settings/images/image_favicon`);
      }
      if (body.image_logo !== workspace.image_logo) {
        await apiRequest("DELETE", `/workspaces/${body._id}/settings/images/image_logo`);
      }
      let payload = {
        ...body,
        workspace_id: body._id,
        layout_type: layoutType,
      };
      if (layoutType === "CUSTOM_NAVIGATION") {
        if (!layoutLandingPageId) {
          toast.error('You must select a landing page when "No Navigation" is selected');
          return;
        }
        payload.layout_landing_page_id = layoutLandingPageId;
      }
      const message = await props.manageWorkspaceSettings({ workspace_id: body._id, ...body, layout_type: layoutType, layout_landing_page_id: layoutLandingPageId });
      refreshWorkspace();
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Section>
      <div className="grid gap-y-4">
        <div>
          <H3 caption="View multiple options for this workspace's end user.">Select layout orientation</H3>
          <div className="w-56">
            <SelectMenu
              size="md"
              options={options}
              startIndex={options.findIndex((option) => option.value === layoutType)}
              setOption={(option) => {
                setLayoutType(option.value);
              }}
            />
          </div>
          <div className={classNames("font-medium text-gray-600 my-3 transition-all duration-200 max-h-[400px]", layoutType === "CUSTOM_NAVIGATION" ? "" : "max-h-[0px] overflow-hidden")}>
            <p className="text-gray-400 text-sm my-3">Custom layouts must have an initial landing page.</p>
            <div className="relative w-56">
              <LandingPageSelector
                workspace={workspace}
                updateLayoutLandingPageId={setLayoutLandingPageId}
              />
              <div className="absolute text-red-600 -top-1 -right-3 z-50">*</div>
            </div>
          </div>
        </div>
        <div className="grid gap-y-6">
          <H3 caption="White-label the customer experience with customize colors and logos.">Choose your colors</H3>
          <StylesBuilder
            data={workspace}
            onSubmit={handleStylesUpdate}
            title="Layout & Styles"
            secondaryTitle="(Colors, logo, favicon, etc.)"
            layoutType={layoutType}
          />
        </div>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return { site: state.site };
};

export default connect(mapStateToProps, { getAuthorizedUser, manageWorkspaceSettings })(WorkspaceLayoutStyles);
