// import { ArchiveBoxIcon as ArchiveBoxIconMini, ArrowUturnLeftIcon, FolderArrowDownIcon, PencilIcon } from "@heroicons/react/20/solid";
import { BoltIcon, Cog6ToothIcon, Cog8ToothIcon, EyeIcon, RectangleStackIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Filter from "src/components/Shared/Filter";
import Modal from "src/components/Shared/Modal";
import MultiColumnLayout from "src/components/Shared/MultiColumnLayout/MultiColumnLayout";
import BodyContentHeader from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/BodyContentHeader";
import HeaderAction from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/HeaderAction";
import UserDateTime from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/UserDateTime";
import UserItems from "src/components/Shared/MultiColumnLayout/MultiColumnLayoutComponents/UserItems";
import ActivityContent from "src/components/Shared/MultiColumnLayout/TabContent/ActivityContent";
import BulkUpload from "src/components/Shared/MultiColumnLayout/TabContent/BulkUpload2";
import ManualInvite from "src/components/Shared/MultiColumnLayout/TabContent/ManualInvite";
import SettingContent from "src/components/Shared/MultiColumnLayout/TabContent/SettingContent";
import SettingsContent from "src/components/Shared/MultiColumnLayout/TabContent/SettingsContent";
import WorkspaceContent from "src/components/Shared/MultiColumnLayout/TabContent/WorkspaceContent";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";

const Users2 = () => {
  const [keyword, setKeyword] = useState("");
  const [inviteModal, setInviteModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const userSelectedItem = {
    name: "Joe Armstrong",
    email: "joearmstrong@example.com",
    status: "Active",
    isAdmin: true,
  };

  const userItems = [
    {
      _id: 1,
      email: "example@email.com",
      name: "Gloria Roberston",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 2,
      email: "example@email.com",
      name: "Virginia Abshire",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: true,
    },
    {
      _id: 3,
      email: "example@email.com",
      name: "Kyle Gulgowski",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 4,
      email: "example@email.com",
      name: "Hattie Haag",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 5,
      email: "example@email.com",
      name: "Wilma Glover",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 6,
      email: "example@email.com",
      name: "Dolores Morissette",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 7,
      email: "example@email.com",
      name: "Guadalupe Walsh",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 8,
      email: "example@email.com",
      name: "Jasmine Hansen",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 9,
      email: "example@email.com",
      name: "Ian Volkman",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 10,
      email: "example@email.com",
      name: "Rafael Klocko",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 11,
      email: "example@email.com",
      name: "Gloria Roberston",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 12,
      email: "example@email.com",
      name: "Virginia Abshire",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 13,
      email: "example@email.com",
      name: "Kyle Gulgowski",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 14,
      email: "example@email.com",
      name: "Hattie Haag",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 15,
      email: "example@email.com",
      name: "Wilma Glover",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 16,
      email: "example@email.com",
      name: "Dolores Morissette",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 17,
      email: "example@email.com",
      name: "Guadalupe Walsh",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 18,
      email: "example@email.com",
      name: "Jasmine Hansen",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 19,
      email: "example@email.com",
      name: "Ian Volkman",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
    {
      _id: 20,
      email: "example@email.com",
      name: "Rafael Klocko",
      date: "1d ago",
      datetime: "2021-01-27T16:35",
      admin: false,
    },
  ];

  // const functionName = () => {};

  // const headerButtons = [
  //   {
  //     _id: "1",
  //     isGroup: true,
  //     groupData: [
  //       {
  //         _id: "1-1",
  //         label: "Reply",
  //         icon: ArrowUturnLeftIcon,
  //         onclick: functionName(),
  //       },
  //       {
  //         _id: "1-2",
  //         label: "Note",
  //         icon: PencilIcon,
  //         onclick: functionName(),
  //       },
  //       {
  //         _id: "1-3",
  //         label: "Assign",
  //         icon: UserPlusIcon,
  //         onclick: functionName(),
  //       },
  //     ],
  //   },
  //   {
  //     _id: "2",
  //     isGroup: false,
  //     label: "Archive",
  //     icon: ArchiveBoxIconMini,
  //     onclick: functionName(),
  //   },
  //   {
  //     _id: "3",
  //     isGroup: false,
  //     label: "Move",
  //     icon: FolderArrowDownIcon,
  //     onclick: functionName(),
  //   },
  // ];

  const userHeaderAction = [
    { _id: 1, name: "Workspaces", type: "", hasIcon: false, icon: "" },
    { _id: 2, name: "Activity", type: "", hasIcon: false, icon: "" },
    { _id: 3, name: "Settings", type: "", hasIcon: false, icon: "" },
    { _id: 4, name: "", type: "hr", hasIcon: false, icon: "" },
    { _id: 5, name: "Impersonate", type: "", hasIcon: true, icon: EyeIcon },
  ];

  const inviteUserTab = [
    { name: "Manual invite", component: <ManualInvite /> },
    { name: "Bulk upload", component: <BulkUpload /> },
  ];

  return (
    <>
      <H1>
        <div>
          Users List
          <div className="text-base text-gray-400 font-normal">Total of 216 users</div>
        </div>
      </H1>
      <MultiColumnLayout
        aside={userItems}
        itemEntry={(user) => <UserItems data={user} />}
        selectedItem={userSelectedItem}
        headerSearch={{ searchText: keyword, onSearchTextChange: setKeyword, type: "text" }}
        headerFilter={() => (
          <>
            <div className="relative">
              <Filter />
            </div>
            <div className="relative">
              <Button
                className="!font-medium !bg-white !border-gray-300 !opacity-100 !text-gray-500 !px-2 hover:!shadow-sm !ring-0"
                onClick={() => setInviteModal(true)}>
                <UserPlusIcon className="w-5 h-5" />
                Invite Members
              </Button>
            </div>
            <div className="relative !ml-auto">
              <Button
                className="!font-medium !bg-white !border-gray-300 !opacity-100 !text-gray-500 !px-0 !py-0 !h-[34px] !w-[34px] hover:!shadow-sm !ring-0"
                onClick={() => setSettingsModal(true)}>
                <Cog6ToothIcon className="w-6 h-6" />
              </Button>
            </div>
          </>
        )}
        headerContent={() => (
          <>
            {/* <ButtonGroups data={headerButtons}/> */}
            <div className="flex space-x-4">
              <UserDateTime />
              <HeaderAction
                data={userHeaderAction}
                xPosition="right"
              />
            </div>
          </>
        )}
        bodyContentHeader={(user) => (
          <BodyContentHeader
            data={user}
            type={"user"}
          />
        )}
        tabs={[
          {
            name: "Workspaces",
            icon: RectangleStackIcon,
            component: (
              <WorkspaceContent
                workspaces={[
                  {
                    name: "McDonalds",
                    tags: ["Quick", "Fastfood"],
                  },
                  {
                    name: "DQ",
                    tags: ["Milkshakes", "Frozen"],
                  },
                ]}
              />
            ),
          },
          { name: "Activity", icon: BoltIcon, component: <ActivityContent /> },
          { name: "Settings", icon: Cog8ToothIcon, component: <SettingsContent /> },
        ]}
      />
      <Modal
        title={"Invite users"}
        onCancel={() => setInviteModal(false)}
        onSuccess={() => setInviteModal(false)}
        isOpen={inviteModal}>
        <Tabs
          newDesign={true}
          tabs={inviteUserTab}
        />
      </Modal>
      <Modal
        title={"User settings"}
        defaultOptions={{ onCancelButtonVisible: false, onSuccessButtonVisible: false }}
        onCancel={() => setSettingsModal(false)}
        isOpen={settingsModal}>
        <SettingContent onClick={() => setSettingsModal(false)} />
      </Modal>
    </>
  );
};

export default Users2;
