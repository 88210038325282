import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { deleteWorkspace } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceIntegrationsList from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationsList";

const WorkspaceSettings = ({ workspaceId, ...props }) => {
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  return (
    <>
      <Section>
        <div className="grid gap-y-10">
          <div className="w-full">
            <H3 caption="Manage the workspace's is_global filters and credentials from one location.">DOMO Integration</H3>
            <WorkspaceIntegrationsList workspaceId={workspaceId} />
          </div>
          <hr className="w-full" />
          <div className="w-full flex items-center">
            <H3 caption="To delete a workspace, make sure you move all resources within it.">Delete workspace</H3>
            <Button
              version="secondary"
              className="h-12 !text-red-300 !border-red-300"
              onClick={() => setDeleteId(workspaceId)}>
              Delete
            </Button>
          </div>
        </div>
      </Section>
      <Modal
        title="Workspace"
        secondaryTitle="Delete"
        isOpen={deleteId ? true : false}
        onCancel={() => setDeleteId(null)}
        onSuccess={async () => {
          await props.deleteWorkspace(deleteId);
          navigate("/workspaces");
        }}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="px-3 py-6 flex flex-col whitespace-nowrap gap-y-1 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this workspace?
          </p>
          <div className="text-gray-400">Once you delete this workspace it's gone for good.</div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { deleteWorkspace })(WorkspaceSettings);
