import { ClipboardDocumentCheckIcon, RectangleStackIcon, UserIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { getUserDetails } from "src/actions/user";
import BackIndicator from "src/components/Navigation/BackIndicator";
import UserPermissions from "src/components/Users/Edit/Permissions/UserPermissions";
import UserProfileDetailsEdit from "src/components/Users/Edit/UserProfileDetailsEdit";
import UserWorkspaceList from "src/components/Users/Edit/Workspaces/UserWorkspaceList";
import UserProfile from "src/components/Users/UserProfile";
import { useLoadData } from "src/hooks/useLoadData";
import GlobalFiltersList from "./GlobalFilters/GlobalFiltersList";

const UserEdit = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const tabs = [
    { name: "View Profile", icon: UserIcon, component: <UserProfileDetailsEdit /> },
    { name: "Workspaces", icon: RectangleStackIcon, component: <UserWorkspaceList /> },
    { name: "Permissions", icon: ClipboardDocumentCheckIcon, component: <UserPermissions /> },
    {
      name: "Global Filters",
      icon: FunnelIcon,
      component: <GlobalFiltersList />,
    },
    // { name: "Settings", icon: Cog8ToothIcon, component: <div>Hello world</div> },
  ];

  useLoadData("post", "/users/details", { body: { id } }, (res) => setUser(res.data));

  return (
    <>
      <H1 item={{ icon: BackIndicator }}>
        <p className="text-gray-300 font-light capitalize">{user?.type === "admin" ? "Admins" : "Users"} /</p>
        <div className="h-10 w-10 rounded-full overflow-hidden border border-gray-100">
          <UserProfile
            user={user}
            size="sm"
          />
        </div>
        {user?.name}
      </H1>
      <Tabs tabs={tabs}></Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails,
    site: state.site,
  };
};

export default connect(mapStateToProps, { getUserDetails })(UserEdit);
