import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { manageSiteSettings } from "src/actions/site";
import { toast } from "react-toastify";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import ToggleHeader from "../Shared/Forms/Toggles/ToggleHeader";

const ActivitySettings = ({ site, manageSiteSettings }) => {
  const [activityWarningEnable, setActivityWarningEnable] = useState(site?.activity_warning_enable || false);
  const [activityWarningView, setActivityWarningView] = useState(site?.activity_warning_view || "");
  const [activityWarningMessage, setActivityWarningMessage] = useState(site?.activity_warning_message || "");
  const [activityLockoutEnable, setActivityLockoutEnable] = useState(site?.activity_lockout_enable || false);
  const [activityLockoutView, setActivityLockoutView] = useState(site?.activity_lockout_view || "");
  const [activityLockoutMinute, setActivityLockoutMinute] = useState(site?.activity_lockout_minute || "");
  const [activityLockoutMessage, setActivityLockoutMessage] = useState(site?.activity_lockout_message || "");
  const [activityAntiAutomationChanged, setActivityAntiAutomationChanged] = useState(false);

  const setPageData = (site) => {
    if (site?._id) {
      setActivityWarningEnable(site.activity_warning_enable || false);
      setActivityWarningView(site.activity_warning_view || "");
      setActivityWarningMessage(site.activity_warning_message || "");
      setActivityLockoutEnable(site.activity_lockout_enable || false);
      setActivityLockoutView(site.activity_lockout_view || "");
      setActivityLockoutMinute(site.activity_lockout_minute || "");
      setActivityLockoutMessage(site.activity_lockout_message || "");
    }
  };

  const onClear = () => {
    setPageData();
  };

  useEffect(() => {
    setPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        activity_warning_enable: activityWarningEnable,
        activity_warning_view: activityWarningView,
        activity_warning_message: activityWarningMessage,
        activity_lockout_enable: activityLockoutEnable,
        activity_lockout_view: activityLockoutView,
        activity_lockout_minute: activityLockoutMinute,
        activity_lockout_message: activityLockoutMessage,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (site?._id) {
      if (
        activityWarningEnable === site.activity_warning_enable &&
        activityWarningView === site.activity_warning_view &&
        activityWarningMessage === site.activity_warning_message &&
        activityLockoutEnable === site.activity_lockout_enable &&
        activityLockoutView === site.activity_lockout_view &&
        activityLockoutMinute === site.activity_lockout_minute &&
        activityLockoutMessage === site.activity_lockout_message
      ) {
        setActivityAntiAutomationChanged(false);
      } else {
        setActivityAntiAutomationChanged(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityWarningEnable, activityWarningView, activityWarningMessage, activityLockoutEnable, activityLockoutView, activityLockoutMinute, activityLockoutMessage]);

  return (
    <>
      <div className="grid space-y-8">
        <div className="bg-white shadow-md sm:px-10 px-4 py-3 sm:py-8 rounded-md flex flex-wrap justify-between w-full">
          <h3 className="mt-3 mb-4 pb-1 text-xl font-semibold text-gray-500">
            Anti-Automation / Abuse Prevention <span className="text-gray-300 font-normal">(BETA)</span>
          </h3>
          <div className="relative w-full mb-5 space-y-20">
            <div className="relative w-full">
              <div className="font-medium text-gray-500 mb-4 flex gap-x-5">
                <ToggleHeader
                  title="Initial warning"
                  subtitle='Warn users of "malicious bot-like" behavior'>
                  <Toggle
                    checked={activityWarningEnable}
                    onChange={setActivityWarningEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex sm:px-8 flex-col w-full gap-y-3 gap-x-5">
                <div className="w-[150px]">
                  <Input
                    name="views"
                    label="Max views (per min.)"
                    value={activityWarningView}
                    onChange={(e) => setActivityWarningView(e.target.value)}
                  />
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    name="message"
                    label="Warning message"
                    value={activityWarningMessage}
                    onChange={(e) => setActivityWarningMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div className="font-medium text-gray-500 mb-4 flex gap-x-5">
                <ToggleHeader
                  title="Temporary lockout"
                  subtitle="Lock out abusive users.">
                  <Toggle
                    checked={activityLockoutEnable}
                    onChange={setActivityLockoutEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex sm:px-8 flex-col w-full gap-y-3 gap-x-5">
                <div className="flex gap-x-4">
                  <div className="w-[150px]">
                    <Input
                      name="views"
                      label="Max views (per min.)"
                      value={activityLockoutView}
                      onChange={(e) => setActivityLockoutView(e.target.value)}
                    />
                  </div>
                  <div className="w-[150px]">
                    <Input
                      name="locked_min"
                      label="Lockout clock (min.)"
                      value={activityLockoutMinute}
                      onChange={(e) => setActivityLockoutMinute(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    name="message"
                    label="Lockout message"
                    value={activityLockoutMessage}
                    onChange={(e) => setActivityLockoutMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end gap-x-4 mt-20 sm:mt-4">
          <Button
            version="gray"
            onClick={onClear}
            disabled={!activityAntiAutomationChanged}>
            Undo changes
          </Button>
          <Button
            onClick={onSubmit}
            disabled={!activityAntiAutomationChanged}>
            Update
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(ActivitySettings);
