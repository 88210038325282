import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { v4 } from "uuid";
import useFetch from "src/hooks/useFetch";
import { apiRequest } from "src/async/apiUtils";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Button from "src/components/Shared/Buttons/Button";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { toast } from "react-toastify";
import { operatorNameConverter } from "src/helpers/operators/operatorNameConverter";

const GlobalFiltersList = () => {
  const user = useSelector((state) => state.auth.user);
  const { id: userId } = useParams();

  const {
    response: { data: operators = [] },
  } = useFetch("/operators/list");

  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filters, setFilters] = useState([]);
  /*
  {
    _id: "644218729b614c326bbf50ba",
    operator_id: "623ae9241760d33b02f98946",
    column_name: "String",
    value_type: "value",
    column_value: "cow",
    trusted_attribute: null
  }
  */

  useEffect(() => {
    if (userId) {
      const loadFilters = async () => {
        try {
          setFiltersLoaded(false);

          const res = await apiRequest("get", `users/${userId}/global-filters`, {});

          if (res.data && res.data.status === 200) {
            setFiltersLoaded(true);
            setFilters(res.data.data);
          } else {
            setFiltersLoaded(true);
          }
        } catch (err) {
          toast.error(err.message);
          setFiltersLoaded(true);
        }
      };

      loadFilters();
    }
  }, [userId]);

  const manageFilters = async (filter = {}, showMessage = false, id = null, type = null) => {
    try {
      const res = await apiRequest("post", `users/${userId}/global-filters/manage`, {
        body: { ...filter, type },
      });

      if (res.data && res.data.status === 200) {
        if (showMessage) {
          toast.success(res.data.message);
        }

        if (res.data.data) {
          setFilters((filters) =>
            filters.map((filter) =>
              filter._id !== id
                ? filter
                : {
                    ...filter,
                    _id: res.data.data,
                  }
            )
          );
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const addFilter = async () => {
    const uniqueId = v4();

    setFilters((filters) => [
      ...filters,
      {
        _id: uniqueId,
        operator_id: null,
        column_name: null,
        value_type: null,
        column_value: null,
        trusted_attribute: null,
      },
    ]);

    await manageFilters({}, false, uniqueId);
  };

  const removeFilter = (id) => {
    const updatedFilters = filters.filter((item) => item._id !== id);
    setFilters(updatedFilters);
    manageFilters({ _id: id }, false, null, "remove");
  };

  return (
    <>
      <div className="py-1 flex justify-between">
        <span className="text-gray-400 self-end">Global filters will be applied to all dashboards that the user has been given access to see.</span>
        <Button
          version="secondary"
          onClick={addFilter}>
          <IconsWithPlus
            strokeColor={"stroke-highlightColor"}
            item={{ icon: FunnelIcon }}
          />
        </Button>
      </div>
      <div className="space-y-3 mt-2 bg-white border border-slate-200 rounded py-3 px-4">
        {!filtersLoaded ? (
          <Preloader />
        ) : !filters.length ? (
          <p className="py-1 flex justify-center text-gray-400">None found</p>
        ) : (
          filters.map((filter) => (
            <WorkspaceIntegrationFiltersItem
              key={filter._id}
              user={user}
              workspaceId={null}
              state={filter}
              operators={operators?.map((operator) => {
                return { ...operator, name: operatorNameConverter(operator.name), id: operator._id };
              })}
              trustedAttributeArr={trustedAttributeArr}
              valueType={valueType}
              setState={(state) => {
                setFilters(filters.map((filter) => (filter._id !== state._id ? filter : state)));

                manageFilters(state, false);
              }}
              removeFilter={() => removeFilter(filter._id)}
            />
          ))
        )}
      </div>
    </>
  );
};

export default GlobalFiltersList;
