import { XMarkIcon } from "@heroicons/react/24/solid";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthorizedUser } from "src/actions/auth";

function NavigationPreviewBox({ workspaceDetails, getAuthorizedUser, removePreviewUser, user, hasImpersonatedUserId }) {
  const navigate = useNavigate();

  const updateLoadedUser = async (ac) => {
    await getAuthorizedUser(ac);
    window.location.reload();
    if (user.type === "admin") {
      navigate("/pages");
    }
    if (user.type === "editor") {
      navigate("/pages");
    }
    if (user.type === "user") {
      //   navigate("/analytics");
    }
  };

  const handleRemovePreviewUser = async () => {
    removePreviewUser();
    if (localStorage.getItem("userId")) {
      const ac = new AbortController();
      await updateLoadedUser(ac.signal);
    }
  };

  return (
    <>
      {hasImpersonatedUserId && (
        <div className={"relative flex flex-col bg-gray-50 px-6 py-3" + (workspaceDetails && workspaceDetails.layout_type === "TOP_NAVIGATION") ? "" : "-mx-2 -mb-4 mt-2"}>
          <div className="sm:flex sm:items-center sm:justify-between">
            <h4 className="sr-only">Select User</h4>
            <div className="flex w-full items-center justify-between gap-x-4 rounded-md border-[1px] bg-gray-50 p-1.5 px-2 shadow-sm transition-all duration-200 hover:shadow-md">
              <div className="flex items-center gap-x-3">
                <p className="flex flex-col p-0 text-sm">
                  {user?.name}
                  <span className="-mt-1 p-0 text-xs text-gray-400">Role: {user?.type}</span>
                </p>
              </div>
              <XMarkIcon
                onClick={handleRemovePreviewUser}
                className="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-500"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    users: Object.values(state.users),
  };
};

export default connect(mapStateToProps, {
  getAuthorizedUser,
})(NavigationPreviewBox);
