import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { toast } from "react-toastify";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";

const PrivacyAndSecurity = ({ site, manageSiteSettings }) => {
  const [userDeactivationEnabled, setUserDeactivationEnabled] = useState(false);
  const [userDeactivationTimeframe, setUserDeactivationTimeframe] = useState("");
  const [userDeletionEnabled, setUserDeletionEnabled] = useState(false);
  const [userDeletionTimeframe, setUserDeletionTimeframe] = useState("");
  const [userInactivityLogoutEnabled, setUserInactivityLogoutEnabled] = useState(site?.user_inactivity_logout_enabled || false);
  const [userInactivityLogoutTimeframe, setUserInactivityLogoutTimeframe] = useState(site?.user_inactivity_logout_timeframe || "");

  const setPageData = (site) => {
    if (site?._id) {
      setUserDeactivationEnabled(site.user_deactivation_enabled || false);
      setUserDeactivationTimeframe(site.user_deactivation_timeframe || "");
      setUserDeletionEnabled(site.user_deletion_enabled || false);
      setUserDeletionTimeframe(site.user_deletion_timeframe || "");
      setUserInactivityLogoutEnabled(site.user_inactivity_logout_enabled || false);
      setUserInactivityLogoutTimeframe(site.user_inactivity_logout_timeframe || "");
    }
  };

  useEffect(() => {
    setPageData(site);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onSubmit = async () => {
    try {
      const message = await manageSiteSettings({
        user_deactivation_enabled: userDeactivationEnabled,
        user_deactivation_timeframe: userDeactivationTimeframe,
        user_deletion_enabled: userDeletionEnabled,
        user_deletion_timeframe: userDeletionTimeframe,
      });

      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="grid space-y-8">
        <div className="bg-white shadow-md sm:px-10 px-4 py-3 sm:py-8 rounded-md flex flex-wrap justify-between w-full">
          <div className="mt-3 mb-4 flex w-full items-center justify-between">
            <h3 className="pb-1 text-xl font-semibold text-gray-500">
              Privacy & Security <span className="text-gray-300 font-normal">(BETA)</span>
            </h3>
          </div>
          <div className="relative w-full mb-5 space-y-20">
            <hr className="my-10 mt-0" />
            <div className="relative w-full">
              <ToggleHeader
                title="Automatic User Deactivation"
                subtitle="(Excluding admins)">
                <Toggle
                  checked={userDeactivationEnabled}
                  onChange={setUserDeactivationEnabled}
                />
              </ToggleHeader>
              <div className="font-light  flex px-8 w-full items-center gap-y-3 gap-x-5">
                <p>
                  <span className="font-semibold">DEACTIVATE</span> users after
                </p>
                <div className="w-[70px]">
                  <Input
                    name="views"
                    value={userDeactivationTimeframe}
                    onChange={(e) => setUserDeactivationTimeframe(e.target.value)}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>

            <div className="relative w-full mt-6">
              <ToggleHeader
                title="Automatic User Deletion"
                subtitle="(Excluding admins)">
                <Toggle
                  checked={userDeletionEnabled}
                  onChange={setUserDeletionEnabled}
                />
              </ToggleHeader>
              <div className="font-light flex px-8 w-full items-center gap-y-3 gap-x-5">
                <p>
                  Permanently <span className="font-semibold">DELETE</span> users after
                </p>
                <div className="w-[70px]">
                  <Input
                    name="views"
                    value={userDeletionTimeframe}
                    onChange={(e) => setUserDeletionTimeframe(e.target.value)}
                  />
                </div>
                <p>days without a login.</p>
              </div>
            </div>
            <div className="w-full mt-6">
              <div className="mt-3 mb-4 flex w-full items-center justify-between">
                <h3 className="pb-1 text-xl font-semibold text-gray-500">
                  Inactivity Manager <span className="text-gray-300 font-normal">(BETA)</span>
                </h3>
              </div>
              <hr className="my-10 mt-0" />
              <div className="relative w-full">
                <ToggleHeader
                  title="Inactivity auto-logout"
                  subtitle="Define how long a user may be inactive.">
                  <Toggle
                    checked={userInactivityLogoutEnabled}
                    onChange={setUserInactivityLogoutEnabled}
                  />
                </ToggleHeader>
                <div className="font-light flex px-8 w-full items-center gap-y-3 gap-x-5">
                  <p>
                    Permanently <span className="font-semibold">DELETE</span> users after
                  </p>
                  <div className="w-[70px]">
                    <Input
                      name="views"
                      label="Time spent inactive (min.)"
                      value={userInactivityLogoutTimeframe}
                      onChange={(e) => setUserInactivityLogoutTimeframe(e.target.value)}
                    />
                  </div>
                  <p>days without a login.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end gap-x-4">
          <Button onClick={onSubmit}>Save</Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(PrivacyAndSecurity);
