import { PaperClipIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { addSSO, getSso, getXmlData, updateSso } from "src/actions/sso";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
// eslint-disable-next-line no-unused-vars

const certificateText = "-----BEGIN CERTIFICATE----- \n (certificate goes here) \n -----END CERTIFICATE-----";

const SsoServiceProvider = ({ site, addSSO, getSso, updateSso, ...props }) => {
  const { id } = useParams();
  const [uploadType, setUploadType] = useState("manual");
  const location = useLocation();
  const [providerName, setProviderName] = useState(location.state && typeof location.state.providerName !== "undefined" ? location.state.providerName : "");
  const [identityURL, setIdentityURL] = useState(location.state && typeof location.state.entityID !== "undefined" ? location.state.entityID : "");
  const [loginURL, setLoginURL] = useState(location.state && typeof location.state.entryPoint !== "undefined" ? location.state.entryPoint : "");
  const [logoutURL, setLogoutURL] = useState(location.state && typeof location.state.logoutUrl !== "undefined" ? location.state.logoutUrl : "");
  const [certificate, setCertificate] = useState(location.state && typeof location.state.cert !== "undefined" ? location.state.cert : "");
  const [buttonText, setButtonText] = useState("");
  const [buttonColor, setButtonColor] = useState("#000073");
  let navigate = useNavigate();
  const [metaURL, setMetaURL] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [previouslyUploadedCert, setPreviouslyUploadedCert] = useState(false);

  useEffect(() => {
    const ac = new AbortController();
    const loadSSO = async () => {
      try {
        const data = await getSso({ id }, ac.signal);
        setProviderName(data.provider_name);
        setIdentityURL(data.identity_url);
        setLoginURL(data.login_url);
        setLogoutURL(data.logout_url);
        setCertificate(data.certificate);
        setPreviouslyUploadedCert(data.certificate ? true : false);
        setButtonText(data.button_text);
        setButtonColor(data.button_color ? data.button_color : "#000073");
      } catch (error) {
        console.dir(error.message);
      }
    };
    if (id) {
      loadSSO();
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSSO = async () => {
    try {
      const message = await addSSO({
        provider_name: providerName,
        identity_url: identityURL,
        login_url: loginURL,
        logout_url: logoutURL,
        certificate: certificate,
        type: true,
        button_text: buttonText,
        button_color: buttonColor,
        permissions: Object.values({}),
      });
      toast.success(message);
      navigate("/sso");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateSSO = async () => {
    try {
      let permission = Object.values({});
      let per = [];
      for (let index = 0; index < permission.length; index++) {
        const element = permission[index];
        per.push({
          page_id: element.page_id,
          operator_id: element.operator_id,
          column_name: element.column_name,
          column_value: element.column_value,
          is_default: element.is_default,
        });
      }
      const msg = await updateSso({
        id: id,
        provider_name: providerName,
        identity_url: identityURL,
        login_url: loginURL,
        logout_url: logoutURL,
        certificate: certificate,
        type: true,
        button_text: buttonText,
        button_color: buttonColor,
        permissions: per,
      });
      toast.success(msg);
      navigate("/sso");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getXMLDataByUrl = async () => {
    if (providerName) {
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await getXmlData({ url: metaURL, type: "url" });
        // if (id) {
        //   navigate("/sso/" + id + "/manual", { state: { ...data, providerName } });
        // } else {
        //   navigate("/sso/manual", { state: { ...data, providerName } });
        // }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const getXMLDataByFile = async () => {
    if (providerName) {
      if (file) {
        getBase64(file, async (result) => {
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await getXmlData({ file: result, type: "file" });

            // navigate("/sso/manual", { state: { ...data, providerName } });
          } catch (error) {
            toast.error(error.message, {
              position: "top-right",
              autoClose: 1700,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } else {
        toast.error("Please select a XML file");
      }
    }
  };

  const getBase64 = async (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.dir("Error: ", error);
    };
  };

  return (
    <>
      <div className="shadow bg-white rounded-md mt-4 p-6">
        <div className="w-ful max-w-4xl pb-8 mb-8 border-b border-solid border-gray-200">
          <h5 className="text-base font-medium mb-4">How will you setup up the metadata?</h5>
          <div className="flex mb-4">
            <input
              name="uploadType"
              type="radio"
              value="manual"
              checked={uploadType === "manual"}
              className="h-5 w-5 text-color-06 focus:ring-0 focus:border-highlightColor border-gray-300 circle cursor-pointer mt-1"
              onChange={() => setUploadType("manual")}
            />
            <div className="ml-3">
              <div className="text-sm text-gray-800 font-medium">Manually</div>
              <div className="text-xs text-gray-400">Fill out this form from your IDP and find the information in its SSO setup.</div>
            </div>
          </div>
          <div className="flex mb-4">
            <input
              name="uploadType"
              type="radio"
              value="upload"
              checked={uploadType === "upload"}
              className="h-5 w-5 text-color-06 focus:ring-0 focus:border-highlightColor border-gray-300 circle cursor-pointer mt-1"
              onChange={() => setUploadType("upload")}
            />
            <div className="ml-3">
              <div className="text-sm text-gray-800 font-medium">Upload File</div>
              <div className="text-xs text-gray-400">Your IDP may also have a metadata file with all this data that you can upload here.</div>
            </div>
          </div>
        </div>

        {uploadType === "manual" && (
          <div className="relative">
            <div className="w-ful max-w-4xl">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    name="column-name"
                    label="Identity Provider Name"
                    value={providerName || ""}
                    onChange={(e) => setProviderName(e.target.value)}
                  />
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    name="column-name"
                    label="IDP Entity ID or Issuer"
                    value={identityURL || ""}
                    onChange={(e) => setIdentityURL(e.target.value)}
                  />
                  <div className="text-xs text-gray-400 mt-1">*You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    name="column-name"
                    label="SAMS Login URL"
                    value={loginURL || ""}
                    onChange={(e) => setLoginURL(e.target.value)}
                  />
                  <div className="text-xs text-gray-400 mt-1">*You can find the SAML Login URL in Your IDP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    name="column-name"
                    label="SAMS Logout URL"
                    value={logoutURL || ""}
                    onChange={(e) => setLogoutURL(e.target.value)}
                  />
                  <div className="text-xs text-gray-400 mt-1">*You can find the SAML Logout URL in Your IDP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                  <label className="text-sm font-medium text-gray-700">X.509 Certificate</label>
                  <textarea
                    className="w-full h-40 text-sm rounded border-gray-300 border outline-none no-shadow py-2 px-3 focus:border-highlightColor"
                    placeholder=""
                    name=""
                    value={certificate || (!previouslyUploadedCert ? certificateText : "")}
                    onChange={(e) => setCertificate(e.target.value)}></textarea>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                  <div className="flex bg-gray-100 p-4 rounded">
                    <input
                      id="comments"
                      name="comments"
                      type="checkbox"
                      className="h-5 w-5 text-color-06 focus:ring-0 focus:border-highlightColor border-gray-300 rounded cursor-pointer mt-1"
                      defaultChecked={true}
                      disabled={true}
                    />
                    <div className="ml-3">
                      <div className="text-sm text-gray-800 font-medium">Character Encoding</div>
                      <div className="text-xs text-gray-400">Uses iconv encoding to convert X509 certificate into correct encoding.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {uploadType === "upload" && (
          <div className="relative">
            <div className="w-ful max-w-4xl">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    name="column-name"
                    label="Identity Provider Name"
                    value={providerName || ""}
                    onChange={(e) => setProviderName(e.target.value)}
                  />
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    name="column-name"
                    label="Metadata URL"
                    value={metaURL || ""}
                    onChange={(e) => setMetaURL(e.target.value)}
                  />
                  <div className="text-xs text-gray-400 mt-1">*You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-1">
                  <Button
                    version="gray"
                    className="h-auto sm:mt-6"
                    type="button"
                    onClick={getXMLDataByUrl}>
                    Fetch Metadata
                  </Button>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <div className="flex justify-center items-center py-8 relative z-0">
                    <span className="block w-full border-b border-gray-200 absolute -z-10 top-1/2 left-0"></span>
                    <span className="text-sm text-center text-gray-500 px-5 bg-white">Or continue with</span>
                  </div>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <label className="text-sm font-medium text-gray-700">Metadata URL</label>
                  <div className="rounded border-gray-300 border focus:border-gray-400 flex">
                    <PaperClipIcon className="w-10 h-10 p-2 text-gray-500" />
                    <input
                      type="text"
                      value={fileName || ""}
                      readOnly
                      className="w-full h-10 text-sm border-0  outline-none no-shadow py-2 px-3 "
                      placeholder=""
                      name=""
                    />
                    <button
                      type="button"
                      className="h-10 px-6 text-sm font-medium text-color-06 relative overflow-hidden">
                      <input
                        type="file"
                        className="absolute opacity-0 top-0 right-0 w-96 h-full cursor-pointer"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                          setFileName(e.target.files[0] ? e.target.files[0].name : "");
                        }}
                        value={undefined}
                      />
                      <span>Upload</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center w-full pt-6 space-x-4">
        <Button
          version="gray"
          type="button"
          onClick={() => navigate("/sso/list")}>
          Cancel
        </Button>
        {uploadType === "upload" ? (
          <Button onClick={getXMLDataByFile}>Update</Button>
        ) : id ? (
          <Button
            type="button"
            onClick={updateSSO}>
            Save
          </Button>
        ) : (
          <Button
            type="button"
            onClick={createSSO}>
            Create
          </Button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { addSSO, getSso, updateSso })(SsoServiceProvider);
