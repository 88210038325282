import { classNames } from "src/helpers/classNames";

const Section = ({ children, className = "" }) => {
  return (
    <div className="grid space-y-8 max-w-7xl">
      <div className={classNames("bg-white shadow-md px-2 sm:px-10 py-4 sm:py-6 sm:pb-10 rounded-md flex flex-col w-full", className)}>{children}</div>
    </div>
  );
};

export default Section;
