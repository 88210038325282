import Accordion from "src/components/Shared/Accordions/Accordion";
import WorkspaceIntegrationClients from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClients";
import WorkspaceIntegrationFilters from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFilters";
import { baseUrl, noFavicon } from "src/config/host";

const WorkspaceIntegrationCredentials = ({ startOpen = false, viewOnly = true, workspace = {}, integration = {} }) => {
  return (
    <Accordion
      startOpen={startOpen}
      headerContent={() => (
        <div className="flex items-center">
          <div className="w-12 h-12 min-w-[48px] min-h-[48px] overflow-hidden border-gray-100 rounded border">
            <img
              src={workspace.image_favicon?.includes(".s3.") ? workspace.image_favicon + "?" + Date.now() : workspace.image_favicon?.includes("data:image/") ? workspace.image_favicon : baseUrl + workspace.image_favicon}
              alt={workspace.name}
              onError={(e) => (e.target.src = `${noFavicon}`)}
            />
          </div>
          <div className="w-full px-4">
            <div className="text-slate-400">{workspace.name}</div>
          </div>
        </div>
      )}>
      <div className="relative p-4 space-y-6">
        <WorkspaceIntegrationClients
          integration={{ ...integration, name: workspace.name }}
          workspaceId={workspace._id}
          viewOnly={viewOnly}
        />
        {workspace?.workspace_type === "DASHBOARD_EMBED" && (
          <WorkspaceIntegrationFilters
            integration={integration}
            workspaceId={workspace.id}
          />
        )}
      </div>
    </Accordion>
  );
};

export default WorkspaceIntegrationCredentials;
