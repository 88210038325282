import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import { baseUrl, noFavicon } from "src/config/host";
import TableRow from "src/components/Shared/Table/TableRow";

const WorkspaceEntry = ({ workspace }) => {
  const navigate = useNavigate();
  const [usersInWorkspaceIds, setUsersInWorkspaceIds] = useState([]);

  useEffect(() => {
    let userIds = [];
    if (workspace?.groups) {
      workspace.groups.forEach((group) => {
        if (group.users?.length > 0) {
          group.users.forEach((user) => {
            if (!userIds.includes(user._id)) {
              userIds.push(user._id);
            }
          });
        }
      });
    }
    setUsersInWorkspaceIds(userIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?.groups]);

  return (
    <TableRow keyId={workspace?._id}>
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <Link
          to={`/workspaces/${workspace._id}`}
          className="text-gray-900 transition-all duration-200 font-medium cursor-pointer hover:underline group">
          <div className="flex gap-4 items-center">
            <div
              style={{ backgroundColor: workspace.top_bar_color }}
              className="w-12 h-12 min-w-[48px] min-h-[48px] overflow-hidden rounded">
              <img
                src={workspace.image_favicon?.includes(".s3.") ? workspace.image_favicon + "?" + Date.now() : workspace.image_favicon?.includes("data:image/") ? workspace.image_favicon : baseUrl + workspace.image_favicon}
                alt="Logo"
                className="w-full h-full object-contain"
                onError={(e) => (e.target.src = `${noFavicon}`)}
              />
            </div>
            {workspace?.name}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="text-gray-900 font-medium">{workspace?.groups.length}</div>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="text-gray-900 font-medium">{usersInWorkspaceIds.length}</div>
      </TRBody>
      <TRBody className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-6">
        <div className="text-gray-900 font-medium">{workspace?.pages.length}</div>
      </TRBody>
      <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/workspaces/${workspace._id}`);
                },
              },
            ]}
          />
        </div>
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceEntry);
