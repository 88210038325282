import { motion, useIsPresent } from "framer-motion";

const TableRow = ({ keyId, children }) => {
  let isPresent = useIsPresent();
  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  return (
    <motion.tr
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ opacity: { duration: 0.3 } }}
      style={generateStyle}
      key={keyId}
      className="relative bg-white">
      {children}
    </motion.tr>
  );
};

export default TableRow
