import { CodeBracketIcon, PresentationChartBarIcon, WindowIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { managePageData } from "src/actions/page";
import QuickMenu from "src/components/Shared/QuickMenu";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import TableRow from "src/components/Shared/Table/TableRow";
import { Tooltip as ReactTooltip } from "react-tooltip";

const PageEntry = ({ workspaceDetails, user = null, pageCredentialOptions, workspaceId = "", page, isDefault, updateDefaultKey, deleteId, setDeleteId, editPage, pagePrev, reloadPagesData, managePageData, ...props }) => {
  const [items, setItems] = useState([]);

  const iconTypes = {
    DOMO: {
      icon: <PresentationChartBarIcon />,
      name: "Dashboard",
    },
    CUSTOM_HTML: {
      icon: <CodeBracketIcon />,
      name: "Custom HTML",
    },
    LANDING_PAGE: {
      icon: <WindowIcon />,
      name: "Landing Page",
    },
  };

  useEffect(() => {
    let item = [];
    if (!props.is_global) {
      if (page.workspace_id?.page?.is_global_template === true) {
        item.push({ name: "Preview", onClick: () => pagePrev(page), color: "text-grey-500" });
      } else {
        if (authorizeUserComponentAccess(user, workspaceId, "page", ["update"])) {
          item.push({ name: "Edit", onClick: () => editPage(page), color: "text-grey-500" });
        }
        item.push({ name: "Preview", onClick: () => pagePrev(page), color: "text-grey-500" });
        if (authorizeUserComponentAccess(user, workspaceId, "page", ["delete"])) {
          item.push({ name: "Delete", onClick: () => setDeleteId(page._id), color: "text-red-500" });
        }
      }
    } else {
      if (user?.type === "admin" && authorizeUserComponentAccess(user, workspaceId, "page", ["update"])) {
        item.push({ name: "Edit", onClick: () => editPage(page), color: "text-grey-500" });
      }
      item.push({ name: "Preview", onClick: () => pagePrev(page), color: "text-grey-500" });
      if (user?.type === "admin" && authorizeUserComponentAccess(user, workspaceId, "page", ["delete"])) {
        item.push({ name: "Delete", onClick: () => setDeleteId(page._id), color: "text-red-500" });
      }
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, page, workspaceId]);

  return (
    <TableRow keyId={page._id}>
      <td className="bg-white px-3 py-3 text-sm">
        <div className="text-gray-900 font-medium flex items-center">
          {page.name}
          {(page.workspace_id?.page?.is_global_template === true || page.workspace_id.length > 0) && (
            <div
              data-tooltip-id={`${page._id}-is_global`}
              className="ml-3 py-1 px-2 flex gap-x-2 rounded-full bg-gray-200/50 text-gray-400 cursor-default">
              <GlobeAltIcon className="w-5 h-5" />
              Global
            </div>
          )}
        </div>
        <ReactTooltip
          id={`${page._id}-is_global`}
          delayShow={200}
          positionStrategy="fixed"
          className="opacity-100 bg-gray-700 rounded px-2 py-2">
          <div className="font-normal leading-[10px]">Global pages are managed by admins.</div>
        </ReactTooltip>
      </td>
      <td className="bg-white px-3 py-3 text-sm">
        <div className="text-gray-900 font-medium">
          <div className="flex item-center gap-x-4">
            <div className="h-5 w-5">{iconTypes[page.page_type]?.icon}</div>
            {iconTypes[page.page_type].name}
          </div>
        </div>
      </td>
      <td className="bg-white px-3 py-3 text-sm">
        <div className="flex justify-end">
          <QuickMenu items={items} />
        </div>
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { managePageData })(PageEntry);
