import React from "react";
import Button from "../../Buttons/Button";
import DnsConfigItem from "./DnsConfigItem";

const DnsConfigContent = ({ data }) => {
  return (
    <>
      <div className="py-5 px-8 space-y-5">
        <div className="relative w-full">
          <div className="text-xl font-semibold">Choose Domain Name System (DNS) Management</div>
          <div className="text-gray-500">This tech was invented in the Paleozoic Era, so we'll help walk you through these steps.</div>
        </div>
        <div className="relative w-full max-w-4xl space-y-5">
          {data.length &&
            data.map((item) => (
              <DnsConfigItem
                key={item._id}
                data={item}
              />
            ))}
        </div>
        <div className="relative w-full flex justify-end">
          <Button
            version="primary"
            className="!h-10">
            Deploy Company
          </Button>
        </div>
      </div>
    </>
  );
};

export default DnsConfigContent;
