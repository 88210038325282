import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";
import { getPages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import { fetchData } from "src/async/fetch";
import GroupModalAdd from "src/components/Groups/GroupModalAdd";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceGroupItem from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupItem";
import { apiUrl } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import WorkspaceManageUsersModal from "../WorkspaceManageUsersModal";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";
import { toast } from "react-toastify";

const WorkspaceGroupList = ({ pageName, getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", workspaceDetails = {}, ...props }) => {
  const trustedAttributeArr = [
    {
      _id: "user.fullName",
      value: "user.fullName",
      name: "Full Name",
    },
    {
      _id: "user.email",
      value: "user.email",
      name: "Email Address",
    },
  ];

  const valueType = [
    {
      _id: "value",
      value: "value",
      name: "Text Value",
    },
    {
      _id: "attribute",
      value: "attribute",
      name: "User Attribute",
    },
  ];

  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteGroup, setDeleteGroup] = useState(null);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [defaultSelectedGroup, setDefaultSelectedGroup] = useState([]);

  const {
    response: { data: pageOptions },
  } = useFetch(`/workspaces/${workspaceId}/pages`, { query: { sort: "ASC", page_type: ["DOMO", "CUSTOM_HTML"] } });

  const {
    response: { data: operatorOptions },
  } = useFetch("/operators/list");

  const loadGroupsData = async () => {
    try {
      let workspaceGroupsData = {};

      try {
        let workspaceGroupsResponse;
        if (pageName === "user") {
          workspaceGroupsResponse = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/users/${userId}/groups`);
        } else {
          workspaceGroupsResponse = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/groups/details`);
        }
        workspaceGroupsData = await workspaceGroupsResponse.json();

        if (workspaceGroupsData.status === 200) {
          setGroups(workspaceGroupsData.data);
          setLoaded(true);
          return workspaceGroupsData.data;
        } else {
          throw new Error(workspaceGroupsData.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.dir(error.message);
    }
  };

  useEffect(() => {
    loadGroupsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteGroup = async () => {
    await apiRequest("delete", `/workspaces/${workspaceId}/groups/${deleteGroup._id}`);
    setDeleteGroup(null);
    loadGroupsData();
  };

  const handleSetGroups = async (groups) => {
    setGroups(groups);
  };

  const userModalOpen = (active, group) => {
    setDefaultSelectedGroup(group);
    setAddUserModalOpen(active);
  };

  const handleGroupUserChanges = async (users) => {
    try {
      const { data } = await apiRequest("post", `/workspaces/groups/users/manage`, {
        body: {
          _id: defaultSelectedGroup._id,
          user_id: users,
        },
      });
      setGroups([
        ...groups.map((grp) => {
          if (grp._id === defaultSelectedGroup._id) {
            return { ...grp, users: data.data, user_id: users };
          } else {
            return grp;
          }
        }),
      ]);
      setAddUserModalOpen(false);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Section className="gap-y-8">
        <div className="flex sm:items-center px-2 gap-x-10">
          <H3 caption="Organize your pages into packages and assign users to have access.">Workspace Groups</H3>
          {!viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["create"]) && (
            <div className="flex justify-end h-10">
              <Button
                version="secondary"
                onClick={() => {
                  setGroupModalOpen(true);
                  setAddGroupStatus(true);
                  setEditGroupStatus(false);
                  setEditId(null);
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: ClipboardDocumentListIcon }}
                />
              </Button>
            </div>
          )}
        </div>
        {loaded ? (
          <div className="relative grid gap-y-4">
            {groups?.length > 0 ? (
              groups.map((group, index) => {
                return (
                  <WorkspaceGroupItem
                    viewOnly={viewOnly}
                    workspaceId={workspaceId}
                    key={group._id}
                    defaultGroup={group}
                    pageOptions={pageOptions}
                    operatorOptions={operatorOptions}
                    setGroups={handleSetGroups}
                    setDeleteGroup={setDeleteGroup}
                    trustedAttributeArr={trustedAttributeArr}
                    valueType={valueType}
                    userModalOpen={userModalOpen}
                    workspaceDetails={workspaceDetails}
                  />
                );
              })
            ) : (
              <NoData />
            )}
          </div>
        ) : (
          <div className={classNames("absolute -bottom-10 right-0 h-10 transition-all duration-300", groups?.length && !loaded ? "opacity-100" : "opacity-0")}>
            <Preloader
              className="h-[40px]"
              circleDimension="5"
              size="sm"
            />
          </div>
        )}
      </Section>
      <GroupModalAdd
        isOpen={groupModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupModalOpen}
        setRefresh={loadGroupsData}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        title="Group"
        secondaryTitle="Add"
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
      />
      <Modal
        title="Group"
        secondaryTitle="Delete"
        isOpen={!!deleteGroup}
        onCancel={() => setDeleteGroup(null)}
        onSuccess={handleDeleteGroup}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {deleteGroup?.name}?</div>
      </Modal>
      <WorkspaceManageUsersModal
        title="Users"
        secondaryTitle="Add"
        isOpen={addUserModalOpen}
        defaultSelectedUsers={defaultSelectedGroup?.users?.map((user) => user._id)}
        onSuccess={(users) => {
          handleGroupUserChanges(users);
        }}
        onCancel={() => setAddUserModalOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getPages,
  manageGroupUser,
})(WorkspaceGroupList);
