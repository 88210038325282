import { useState } from "react";
import { connect } from "react-redux";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import Modal from "src/components/Shared/Modal";
import UserProfile from "src/components/Users/UserProfile";
import useFetch from "src/hooks/useFetch";

const WorkspaceManageUsersModal = ({ workspaceDetails, title = "", secondaryTitle = "", user, defaultSelectedUsers = [], isOpen, onCancel = () => {}, onSuccess = () => {}, isPermissionPage = false, ...props }) => {
  const [selectedUsers, setSelectedUsers] = useState(defaultSelectedUsers);

  const {
    response: { data: workspaceUsers = [] },
  } = useFetch(`/users/list`, { method: "post", data: { sort_direction: "ASC" } });

  const handleSelectedUsers = (updatedSelectedUsers = []) => {
    let userIds = [];
    if (isPermissionPage) {
      if (user?.type === "admin" || (workspaceDetails?.user_permissions && workspaceDetails?.user_permissions?.permission_delete)) {
        userIds = [];
      } else {
        userIds = defaultSelectedUsers;
      }
    }
    for (let i = 0; i < updatedSelectedUsers.length; i++) {
      const updatedSelectedUser = updatedSelectedUsers[i];
      if (updatedSelectedUser.selected) {
        userIds.push(updatedSelectedUser.key);
      }
    }
    setSelectedUsers(userIds);
  };
  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={() => onSuccess(selectedUsers)}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}>
        <div className="relative min-h-[200px] py-4">
          <MultiSelectObject
            defaultOptions={workspaceUsers.map((user) => {
              return {
                key: user._id,
                value: user.name,
                iconValue: { icon: UserProfile, params: { user, className: "h-6 w-6 rounded-full overflow-hidden", size: "sm" } },
                secondaryValue: user.email,
                selected: !!defaultSelectedUsers.find((defaultUser) => defaultUser === "" + user._id),
                object: user,
              };
            })}
            searchableFields={["name", "email"]}
            title="Assign users"
            onChange={handleSelectedUsers}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(WorkspaceManageUsersModal);
