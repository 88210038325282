import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import WorkspaceIntegrationItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationItem";
import useFetch from "src/hooks/useFetch";

const WorkspaceIntegrationsList = ({ workspaceId }) => {
  const {
    response: { data: integrations },
    status: { done: integrationsLoaded },
  } = useFetch(`/workspaces/${workspaceId}/integrations`);

  return (
    <>
      {integrationsLoaded ? (
        integrations?.length > 0 &&
        integrations.map((integration, index) => {
          return (
            <WorkspaceIntegrationItem
              key={index}
              integration={integration}
              workspaceId={workspaceId}
              is_global={false}
            />
          );
        })
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default WorkspaceIntegrationsList;
