import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getAuthorizedUser } from "src/actions/auth";
import { getOperators } from "src/actions/operator";
import { getPages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { fetchData } from "src/async/fetch";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import GroupModal from "src/components/Groups/GroupModalEdit";
import WorkspaceGroupItemOldVersion from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupItemOldVersion";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import { apiUrl } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
/* import { useLoadData } from "src/hooks/useLoadData"; */

const WorkspaceGroupListOldVersion = ({ pageName, getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isAddUserModal, setIsAddUserModal] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState("");
  const [currentGroupUserId, setCurrentGroupUserId] = useState([]);
  const [pageList, setPageList] = useState([]);

  // useLoadData("get", `/pages/credentials`, {}, (res) => setEditPageCredentialOptions(res.data));

  const loadGroupsData = async (ac = {}) => {
    try {
      let workspaceGroupsData = {};

      try {
        let workspaceGroupsResponse;
        if (pageName === "user") {
          workspaceGroupsResponse = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/users/${userId}/groups`);
        } else {
          workspaceGroupsResponse = await fetchData("GET", `${apiUrl}/workspaces/${workspaceId}/groups`);
        }
        workspaceGroupsData = await workspaceGroupsResponse.json();

        if (workspaceGroupsData.status === 200) {
          setGroups(workspaceGroupsData.data);
          setLoaded(true);
          return workspaceGroupsData.data;
        } else {
          throw new Error(workspaceGroupsData.message);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.dir(error.message);
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    const loadPageAndOperatorData = async () => {
      props
        .getPages({ workspace_id: workspaceId }, ac.signal)
        .then((data) => {
          data = data.filter((page) => page.page_type !== "LANDING_PAGE");
          setPageList(data);
          props
            .getOperators({}, ac.signal)
            .then((oprtrs) => {
              loadGroupsData(ac);
            })
            .catch((err) => console.dir(err));
        })
        .catch((err) => console.dir(err));
    };
    if (workspaceId) {
      loadPageAndOperatorData();
    }
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, props.me]);

  const editWorkspaceGroup = async () => {
    try {
      const data = await props.editWorkspaceGroupDetails({ groups, workspaceId });
      toast.success(data);
    } catch (error) {
      // toast.success(error.message);
    }
  };

  const assignUserToGroup = async () => {
    try {
      const data = await props.manageGroupUser({
        _id: currentGroupId,
        user_id: currentGroupUserId,
      });
      await loadGroupsData();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setIsAddUserModal(false);
    setCurrentGroupId("");
    setCurrentGroupUserId([]);
  };

  const handleSetGroups = async (groups) => {
    setGroups(groups);
  };

  return (
    <>
      {loaded ? (
        <div className="relative grid gap-y-4">
          {!viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["create"]) && (
            <div className="flex justify-end">
              <Button
                version="secondary"
                onClick={() => {
                  setGroupModalOpen(true);
                  setAddGroupStatus(true);
                  setEditGroupStatus(false);
                  setEditId(null);
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: ClipboardDocumentListIcon }}
                />
              </Button>
            </div>
          )}
          {groups?.length > 0 ? (
            groups.map((group, index) => {
              return (
                <WorkspaceGroupItemOldVersion
                  viewOnly={viewOnly}
                  workspaceId={workspaceId}
                  key={group._id}
                  groups={groups}
                  group={group}
                  users={[]}
                  pages={pageList}
                  setGroups={handleSetGroups}
                  index={index}
                  refresh={loadGroupsData}
                  isAddUserModal={isAddUserModal}
                  setIsAddUserModal={setIsAddUserModal}
                  setCurrentGroupId={setCurrentGroupId}
                  setCurrentGroupUserId={setCurrentGroupUserId}
                />
              );
            })
          ) : (
            <NoData />
          )}

          {groups?.length > 0 && !viewOnly && authorizeUserComponentAccess(props.me, workspaceId, "group", ["update"]) && (
            <div className="flex justify-end">
              <Button
                version="primary"
                onClick={editWorkspaceGroup}>
                Save
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className={classNames("absolute -bottom-10 right-0 h-10 transition-all duration-300", groups?.length && !loaded ? "opacity-100" : "opacity-0")}>
          <Preloader
            className="h-[40px]"
            circleDimension="5"
            size="sm"
          />
        </div>
      )}

      <GroupModal
        isOpen={groupModalOpen}
        defaultWorkspaceId={workspaceId}
        setIsOpen={setGroupModalOpen}
        setRefresh={loadGroupsData}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        title="Group"
        secondaryTitle={editGroupStatus ? "Edit" : "Add"}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
      />
      <WorkspaceManageUsersModal
        title="Users"
        secondaryTitle="Add"
        isOpen={isAddUserModal}
        setIsOpen={setIsAddUserModal}
        selectedUser={currentGroupUserId}
        setSelectedUser={setCurrentGroupUserId}
        onSubmit={assignUserToGroup}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getPages,
  getOperators,
  manageGroupUser,
})(WorkspaceGroupListOldVersion);
