import React, { useEffect, useState } from "react";
import UserProfile from "src/components/Users/UserProfile";

const Members = ({ group }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (group.users?.length > 0) {
      setUsers(group.users.slice(0, 3));
    }
  }, [group?.users]);

  return (
    <>
      <div className="w-36 flex items-center justify-end gap-4">
        <div className="flex items-center -space-x-2">
          {users &&
            users.map((user, index) => {
              return (
                <div
                  key={group._id + index + user._id}
                  className="w-6 h-6 overflow-hidden rounded-full z-20 ring-2 ring-white ring-offset-0">
                  <UserProfile
                    user={user}
                    size="sm"
                  />
                </div>
              );
            })}
        </div>
        <div className="text-sm text-slate-400 font-medium flex items-center justify-end">
        {group.users?.length > 0 ?
          group.users?.length > 3 ? (
            <>
              +{group.users.length - 3} other{group.users.length - 3 > 1 ? "s" : ""}
            </>
          ) : (
            group.users?.length + " users"
          ) : "No users"}
        </div>
      </div>
    </>
  );
};

export default Members;
