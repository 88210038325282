import { Bars3Icon, PlusIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import { classNames } from "src/helpers/classNames";
import * as Yup from "yup";
import DisplayPageCondition from "./DisplayPageCondition";

const DisplayPageForm = ({ pageOptions = [], operatorOptions = [], permission = {}, setPermission = () => {}, id, removePermission = () => {}, trustedAttributeArr, valueType, draggableProps, dragHandleProps, innerRef, workspaceDetails, ...props }) => {
  // const embedTypes = {
  //   DOMO: "DOMO Page",
  //   EMBED_CARD: "DOMO Card",
  //   CUSTOM_HTML: "Custom HTML",
  //   LANDING_PAGE: "Landing Page",
  // };

  const [pageType, setPageType] = useState("");
  const [pageCopied, setPageCopied] = useState(false);

  useEffect(() => {
    if (pageCopied) {
      setTimeout(() => {
        setPageCopied(false);
      }, 6000);
    }
  }, [pageCopied]);

  const handleConditionAdd = () => {
    setPermission({
      ...permission,
      filters: [
        ...permission.filters,
        {
          column_name: "",
          operator_id: null,
          value_type: "value",
          column_value: "",
          trusted_attribute: "",
        },
      ],
    });
  };

  useEffect(() => {
    let index = pageOptions.findIndex((op) => op._id === permission?.page_id);
    if (index !== -1) {
      setPageType(pageOptions[index].page_type);
      if (pageOptions[index].page_type === "CUSTOM_HTML") {
        setPermission({
          ...permission,
          filters: [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOptions, permission?.page_id]);

  return (
    <div
      className="relative w-full max-w-6xl"
      ref={innerRef}
      {...draggableProps}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          page_id: permission?.page_id || "",
        }}
        validationSchema={Yup.object({
          page_id: Yup.string().required("Required"),
        })}>
        {({ values, errors, touched }) => {
          const handleOnChange = (e) => {
            setPermission({
              ...permission,
              [e.target.name]: e.target.value,
            });
          };
          return (
            <Form
              onChange={handleOnChange}
              className="relative w-full max-w-6xl bg-white rounded">
              <div className={`relative flex border border-gray-200 rounded-md w-full  ${pageType === "DOMO" ? "rounded-br-none" : ""}`}>
                <button
                  {...dragHandleProps}
                  type="button"
                  className="w-10 min-w-[40px] flex items-center justify-center border-r border-gray-200 text-gray-700 outline-none ring-0 bg-gray-100 cursor-move">
                  <Bars3Icon className="w-5 h-5 stroke-2" />
                </button>
                <div className="w-full flex items-center justify-between py-3 px-4">
                  <div className="w-64">
                    <Input
                      name="page_id"
                      as="select"
                      value={values.page_id}>
                      <option value="">Select</option>
                      {pageOptions.map((pageOption) => (
                        <option
                          key={pageOption._id}
                          value={pageOption._id}>
                          {pageOption.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div className="flex">
                    {workspaceDetails?.layout_type === "CUSTOM_NAVIGATION" && permission.page_id && (
                      <>
                        <button
                          data-tooltip-id={`${props?.id}-custom-link`}
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(`${props?.site?.site_domain}/workspaces/${props?.workspaceId}/page/${permission?.page_id}`);
                            setPageCopied(true);
                          }}
                          className="hidden sm:flex w-8 h-8 items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                          <div className="relative">
                            <Square2StackIcon className={classNames("w-6 h-6 transition-all duration-150", pageCopied ? "text-green-600/70" : "")} />
                              <div className={classNames("bg-white absolute -top-2 -right-2 rounded-full opacity-0 transition-all duration-150", pageCopied ? "opacity-100" : "")}>
                                <CheckCircleIcon className="w-5 h-5 text-green-600/50" />
                              </div>
                          </div>
                        </button>
                        <ReactTooltip
                          id={`${props?.id}-custom-link`}
                          delayShow={200}
                          positionStrategy="fixed"
                          className="opacity-100 bg-gray-700 rounded px-2 py-2">
                          <div className="font-normal leading-[10px]">Copy page link.</div>
                        </ReactTooltip>
                      </>
                    )}
                    <button
                      type="button"
                      onClick={removePermission}
                      className="w-8 h-8 flex items-center justify-center border-gray-200 rounded-md text-gray-500 hover:text-gray-600 outline-none ring-0 transition-all duration-200 hover:bg-gray-50">
                      <TrashIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {pageType === "DOMO" && (
        <div className="relative ml-[40px] border border-t-0 grid gap-y-2 border-gray-200 py-4 px-4 rounded-b-md bg-white border-b-rounded">
          {permission.filters.map((condition, index) => (
            <DisplayPageCondition
              key={id + "_" + index}
              condition={condition}
              setCondition={(e) =>
                setPermission({
                  ...permission,
                  filters: permission.filters.map((p, i) => {
                    if (i === index) {
                      return e;
                    } else {
                      return p;
                    }
                  }),
                })
              }
              operatorOptions={operatorOptions}
              removeCondition={(e) =>
                setPermission({
                  ...permission,
                  filters: permission.filters.filter((p, i) => i !== index),
                })
              }
              trustedAttributeArr={trustedAttributeArr}
              valueType={valueType}
            />
          ))}
          <div className="relative flex">
            <button
              onClick={handleConditionAdd}
              type="button"
              className="flex items-center justify-center h-10 py-0 px-0 text-base font-medium text-highlightColor gap-2">
              <PlusIcon className="w-4 h-4 stroke-2" /> Add Condition
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(DisplayPageForm);
