import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addUser, getGroupData } from "src/actions/user";
import BackIndicator from "src/components/Navigation/BackIndicator";
import Button from "src/components/Shared/Buttons/Button";
import { H1 } from "src/components/Shared/Text/Headers";
import AddUserForm from "src/components/Users/Add/AddUserForm";

const ManageUserSection = ({ pages, operators, groups, addUser, site, ...props }) => {
  const [formCount, setFormCount] = useState([0]);
  const [userState, setUserState] = useState({});
  const [isRefresh] = useState(false);

  const navigate = useNavigate();
  const isAdmin = window.location.pathname.includes("admins/add");

  // Load groups
  useEffect(() => {
    const ac = new AbortController();

    const loadGroups = async () => {
      try {
        await props.getGroupData({}, ac.signal);
      } catch (error) {
        console.dir(error.message);
      }
    };

    loadGroups();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const onSubmit = async () => {
    try {
      let userData = [];

      Object.values(userState).forEach((user) => {
        userData.push({ ...user, type: isAdmin ? "admin" : "user" });
      });

      await addUser({
        users: userData,
      });

      if (isAdmin) {
        return navigate("/admins");
      } else {
        return navigate("/users");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <H1 item={{ icon: BackIndicator }}>
        <p className="text-gray-300 font-light capitalize">{isAdmin ? "admin" : "user"}s/</p>Create
      </H1>

      <div className="my-3 w-full flex items-center justify-between">
        <h3 className="pl-2 text-xl font-medium text-gray-500">Complete the new {isAdmin ? "admin" : "user"} form</h3>
      </div>
      {formCount.map((count, i) => {
        return (
          <AddUserForm
            key={i}
            isAdmin={isAdmin}
            index={count}
            formCount={formCount}
            setFormCount={setFormCount}
            state={userState}
            setState={setUserState}
            groups={groups}
          />
        );
      })}

      <div className="w-full flex justify-end border-t-[1px] border-t-gray-200 mt-5 pt-3 gap-x-3">
        <Button
          version="gray"
          type="button"
          onClick={() => {}}>
          Clear all
        </Button>
        <Button
          type="button"
          onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    groups: Object.values(state.groups),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getGroupData, addUser })(ManageUserSection);
