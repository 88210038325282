import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Input from "src/components/Shared/Forms/Inputs/Input";
import useFetch from "src/hooks/useFetch";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceIntegrationItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationItem";
import WorkspaceIntegrationCredentials from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegrationCredentials";

const GlobalSettings = () => {
  const {
    response: { data: site },
    refreshData: refreshSite,
  } = useFetch("/site-settings/unprotected");

  const {
    response: { data: integrations },
    status: { done: integrationsLoaded },
  } = useFetch(`/integrations/global`);

  const {
    response: { data: workspacesWithIntegrations },
    status: { done: workspacesWithIntegrationsLoaded },
  } = useFetch(`/workspaces/integrations`);

  const [siteDomain, setSiteDomain] = useState("");

  const onSubmit = async () => {
    try {
      let body = {
        site_domain: siteDomain,
      };
      await apiRequest("put", "/site-settings", { body });

      refreshSite();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Section>
          <H3 caption="Setup general website items for the best user experience.">General Settings</H3>
          <div className="flex flex-wrap w-full gap-y-6 gap-x-4">
            <div className="sm:w-1/2 w-full px-2">
              <Input
                name="site-domain"
                label="User Access URL"
                value={siteDomain || site.site_domain}
                onChange={(e) => setSiteDomain(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-x-4 items-end">
              <div className="h-10 flex gap-x-2">
                <Button
                  version="gray"
                  disabled={site.site_domain === siteDomain || siteDomain === ""}
                  onClick={() => setSiteDomain(site.site_domain)}>
                  Undo
                </Button>
                <Button
                  disabled={site.site_domain === siteDomain || siteDomain === ""}
                  onClick={onSubmit}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Section>
        <Section className="gap-y-6">
          <H3 caption="Below list credentials can be used by admins in all workspaces.">Global Credentials</H3>
          {integrationsLoaded ? (
            integrations?.length > 0 &&
            integrations.map((integration) => {
              return (
                <WorkspaceIntegrationItem
                  key={integration._id}
                  integration={integration}
                  workspaceId={null}
                  is_global={true}
                />
              );
            })
          ) : (
            <Preloader />
          )}
          <hr />
          <div>
            <H3 caption="Credential access for app integration for editors (and admin users) of a particular workspace.">Workspace Credentials</H3>
            <div className="grid gap-y-4">
              {workspacesWithIntegrationsLoaded ? (
                workspacesWithIntegrations?.length > 0 &&
                workspacesWithIntegrations.map((workspaceWithIntegrations) => {
                  return (
                    <Fragment key={workspaceWithIntegrations._id}>
                      {workspaceWithIntegrations?.integrations?.length > 0 &&
                        workspaceWithIntegrations.integrations.map((integration) => {
                          return (
                            <WorkspaceIntegrationCredentials
                              key={integration._id}
                              integration={integration}
                              workspace={workspaceWithIntegrations}
                              viewOnly={true}
                            />
                          );
                        })}
                    </Fragment>
                  );
                })
              ) : (
                <Preloader />
              )}
            </div>
          </div>
        </Section>
      </div>
    </>
  );
};

export default GlobalSettings;
