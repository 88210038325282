import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { userWorkspacesAndAssociatedPermissions, userPermissionList } from "src/actions/workspace";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { classNames } from "src/helpers/classNames";
import UserPermissionTabData from "./UserPermissionTabData";

const UserPermissions = ({ ...props }) => {
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("user");
  const tabs = [
    { name: "Users", code: "user" },
    { name: "Groups", code: "group" },
    { name: "Pages", code: "page" },
    { name: "Permissions", code: "permission" },
    { name: "Layout & Styles", code: "layout" },
    { name: "Integrations", code: "integration" },
  ];

  useEffect(() => {
    if (id) {
      const ac = new AbortController();
      const loadData = async () => {
        // await props.userPermissionList({ user_id: id }, ac.signal);

        const workspace = await props.userWorkspacesAndAssociatedPermissions({ user_id: id }, ac.signal);
        setWorkspaceList(workspace);
        setLoaded(true);
      };
      loadData();
      return () => ac.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className="relative w-full rounded-md space-y-4 h-auto">
        {loaded ? (
          <>
            <div className="relative w-full max-w-5xl shadow rounded-md bg-white border border-slate-100">
              <div className="relative mb-5 bg-slate-100">
                <nav
                  className="flex"
                  aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.code}
                      onClick={() => setSelectedTab(tab.code)}
                      className={classNames(tab.code === selectedTab ? "!bg-white text-slate-800 " : "text-slate-400 hover:bg-slate-50 hover:text-slate-600", "py-4 px-6 bg-transparent w-full flex justify-center items-center rounded-t-md cursor-pointer")}
                      aria-current={tab.code === selectedTab ? "page" : undefined}>
                      <span className="font-semibold">{tab.name}</span>
                    </div>
                  ))}
                </nav>
              </div>
              <div className="mt-4">
                <UserPermissionTabData
                  workspaceList={workspaceList}
                  selectedTab={selectedTab}
                />
              </div>
            </div>
          </>
        ) : (
          <Preloader />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { userWorkspacesAndAssociatedPermissions, userPermissionList })(UserPermissions);
