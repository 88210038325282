// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import InputDropBox from "src/components/Shared/Forms/Inputs/InputDropBox";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { baseUrl } from "src/config/host";

const NavigationBlock = ({ navigation = {}, index = "0", navigationPageList = [], newPage = {}, setNewPage = () => {} }) => {
  const [isImgAvail, setIsImgAvail] = useState(true);

  return (
    <div className="relative w-full bg-white py-4 px-5 rounded shadow border border-slate-200 mb-4">
      <button
        onClick={() => {
          setNewPage({
            ...newPage,
            navigation_blocks: newPage.navigation_blocks.filter((nv, i) => i !== index),
          });
        }}
        type="button"
        className="absolute top-3 group right-3 rounded-md transition-colors duration-150 hover:bg-gray-300/50">
        <XMarkIcon className="h-5 w-5 transition-colors duration-150 text-gray-600 group-hover:text-gray-700" />
      </button>
      <div className="relative w-full space-y-4 max-w-3xl pt-3">
        <div className="relative w-full mb-4">
          <Input
            name="title"
            label="Title"
            value={navigation.title}
            onChange={(e) => {
              setNewPage({
                ...newPage,
                navigation_blocks: newPage.navigation_blocks.map((nv, i) => {
                  if (i === index) {
                    return { ...nv, title: e.target.value };
                  } else {
                    return nv;
                  }
                }),
              });
            }}
          />
        </div>
        <div className="relative w-full flex mb-4 gap-x-4">
          {isImgAvail || navigation.image ? (
            <>
              <div className="w-full">
                <div className="w-full rounded">
                  <div className="relative w-[400px] h-auto overflow-hidden rounded">
                    <div
                      onClick={() => {
                        setNewPage({
                          ...newPage,
                          navigation_blocks: newPage.navigation_blocks.map((nv, i) => {
                            if (i === index) {
                              return { ...nv, image: "" };
                            } else {
                              return nv;
                            }
                          }),
                        });
                      }}
                      className="group absolute top-3 right-3 bg-gray-200/50 p-1 rounded cursor-pointer hover:bg-gray-200/90 transition-all duration-100">
                      <PencilSquareIcon className="h-6 w-6 text-gray-700 group-hover:text-gray-900 transition-all duration-100" />
                    </div>
                    <img
                      src={navigation.image?.includes(".s3.") ? navigation.image + "?" + Date.now() : navigation.image?.includes("data:image/") ? navigation.image : baseUrl + navigation.image}
                      onError={() => setIsImgAvail(false)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <InputDropBox
              className="h-full"
              name="image"
              label="Image"
              setImage={(value) => {
                setNewPage({
                  ...newPage,
                  navigation_blocks: newPage.navigation_blocks.map((nv, i) => {
                    if (i === index) {
                      return { ...nv, image: value };
                    } else {
                      return nv;
                    }
                  }),
                });
              }}
            />
          )}
        </div>

        <div className="relative w-full mb-4">
          <label
            htmlFor="title"
            className="flex justify-between text-sm font-medium text-gray-700">
            Links
          </label>
          {navigation.links.map((link, index2) => {
            return (
              <div
                key={index + "_" + index2}
                className="flex space-x-4 mb-1">
                <div className="w-3/6">
                  <Input
                    name="link"
                    label=""
                    placeholder="Link Text"
                    value={link.link_text}
                    onChange={(e) => {
                      setNewPage({
                        ...newPage,
                        navigation_blocks: newPage.navigation_blocks.map((nv, i) => {
                          if (i === index) {
                            let links = [];
                            nv.links.forEach((lk, j) => {
                              if (j === index2) {
                                links.push({ ...lk, link_text: e.target.value });
                              } else {
                                links.push(lk);
                              }
                            });
                            return { ...nv, links: links };
                          } else {
                            return nv;
                          }
                        }),
                      });
                    }}
                  />
                </div>
                <div className="w-2/6">
                  <SelectMenu
                    startIndex={navigationPageList.findIndex((lt) => lt.value === link.page_id)}
                    options={navigationPageList || [{ key: "Select Page", value: -1 }]}
                    setOption={(option) => {
                      setNewPage({
                        ...newPage,
                        navigation_blocks: newPage.navigation_blocks.map((nv, i) => {
                          if (i === index) {
                            let links = [];
                            nv.links.forEach((lk, j) => {
                              if (j === index2) {
                                links.push({ ...lk, page_id: option.value });
                              } else {
                                links.push(lk);
                              }
                            });
                            return { ...nv, links: links };
                          } else {
                            return nv;
                          }
                        }),
                      });
                    }}
                  />
                </div>
                {index2 > 0 && (
                  <button
                    type="button"
                    className="rounded-md transition-colors duration-150"
                    onClick={() => {
                      setNewPage({
                        ...newPage,
                        navigation_blocks: newPage.navigation_blocks.map((item, i) =>
                          i !== index
                            ? item
                            : {
                                ...item,
                                links: item.links.filter((item2, i2) => i2 !== index2),
                              }
                        ),
                      });
                    }}>
                    <XMarkIcon className="h-5 w-5 transition-colors duration-150 text-gray-600 group-hover:text-gray-700" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <div className="relative w-full mb-4">
          <Button
            onClick={() => {
              setNewPage({
                ...newPage,
                navigation_blocks: newPage.navigation_blocks.map((nv, i) => {
                  if (i === index) {
                    return {
                      ...nv,
                      links: [
                        ...nv.links,
                        {
                          link_text: "",
                          page_id: null,
                        },
                      ],
                    };
                  } else {
                    return nv;
                  }
                }),
              });
            }}
            type={"black"}>
            Add Link
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(NavigationBlock);
