import React from 'react';
import Input from "../../Forms/Inputs/Input";

const AddCompany = () => {
  return (
    <>
      <div className="relative py-4 px-4 bg-white border border-gray-200 rounded-xl">
        <div className="flex gap-5 py-5">
          <div className="w-full max-w-md">
            <div className="mb-4">
              <Input
                label={"Company Name"}
                name={"company-name"}
                type={"text"}
                placeholder={"Enter Company Name"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompany;