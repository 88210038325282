import { classNames } from "src/helpers/classNames";

const FrontLabeledInput = ({ label, autoComplete = "off", placeholder, name, disabled = false, value = "", type = "text", onChange = () => {}, onFocus = () => {} }) => {
  return (
    <div className={classNames("h-10 flex rounded-md shadow-sm w-full", disabled ? "cursor-not-allowed" : "")}>
      <div className="w-[110px] min-w-[110px] inline-flex items-center px-3 rounded-l-md border border-gray-300 border-r-0 bg-gray-50 text-gray-500">{label}</div>
      <input disabled={disabled} type={type} placeholder={placeholder} autoComplete={autoComplete} onFocus={onFocus} className={classNames("h-10 flex-1 min-w-0 block w-full px-3 py-2 rounded-r-md border-gray-300 focus:ring-0 focus:border-highlightColor", disabled ? "cursor-not-allowed text-gray-700" : "")} value={value} onChange={onChange} />
    </div>
  );
};

export default FrontLabeledInput;
