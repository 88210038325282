import { ArrowPathIcon, CheckCircleIcon, ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, Cog8ToothIcon, PaintBrushIcon, WindowIcon } from "@heroicons/react/24/outline";
import { CameraIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAuthorizedUser } from "src/actions/auth";
import { apiRequest } from "src/async/apiUtils";
import BackIndicator from "src/components/Navigation/BackIndicator";
import PageList from "src/components/Pages/PageList";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Tabs from "src/components/Shared/Tabs/Tabs";
import WorkspaceGroupList from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupList";
import WorkspacePermissions from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissions";
import WorkspaceSettings from "src/components/Workspaces/Workspace/Settings/WorkspaceSettings";
import WorkspaceUserList from "src/components/Workspaces/Workspace/Users/WorkspaceUsersList";
import WorkspaceLayoutStyles from "src/components/Workspaces/Workspace/WorkspaceLayoutStyles/WorkspaceLayoutStyles";
import WorkspaceTag from "src/components/Workspaces/WorkspaceTag";
import { baseUrl, noLogo } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import useDebounce from "src/hooks/useDebounce";
import { useLoadData } from "src/hooks/useLoadData";

const WorkspaceEdit = ({ ...props }) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [workspace, setWorkspace] = useState({});
  const [workspaceLoaded, setWorkspaceLoaded] = useState({});
  const [tabs, setTabs] = useState([]);
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [focusedOnTag, setFocusedOnTag] = useState(false);

  const [tagList, setTagList] = useState([]);

  const handleWorkspaceUpdate = async (name = " ") => {
    await apiRequest("put", `/workspaces/${id}/settings`, { body: { name } });
  };

  const [debouncedNameUpdate, debounceNameStatus] = useDebounce(handleWorkspaceUpdate, 500);

  const handleChange = async (e) => {
    setName(e.target.value);
    debouncedNameUpdate(e.target.value);
  };

  const handleTag = async (e) => {
    if (e.code === "Enter" || e.code === "Comma" || e.submit === true) {
      if (e.submit !== true) {
        e.preventDefault();
      }
      if (tagList.findIndex((tg) => tg.toLowerCase() === tag.toLowerCase()) === -1) {
        const tags = [...tagList, tag];
        setTagList(tags);
        setTag("");
        await apiRequest("put", `/workspaces/${id}/settings`, { body: { tags } });
      }
    }
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
    await apiRequest("put", `/workspaces/${id}/settings`, { body: { tags: tagList } });
  };

  useEffect(() => {
    if (workspace) {
      setName(workspace.name || "");
      setTagList(workspace.tags || []);
    }
  }, [workspace]);

  useEffect(() => {
    setTabs([
      {
        name: "Pages",
        icon: WindowIcon,
        component: <PageList workspaceId={id} />,
        hidden: !authorizeUserComponentAccess(props.me, id, "page", [], true),
      },
      {
        name: "Groups",
        icon: ClipboardDocumentListIcon,
        component: (
          <WorkspaceGroupList
            workspaceId={id}
            workspaceDetails={workspace}
            pageName={"workspace"}
          />
        ),
        hidden: !authorizeUserComponentAccess(props.me, id, "group", [], true),
      },
      {
        name: "Users",
        icon: ClipboardDocumentListIcon,
        component: (
          <WorkspaceUserList
            workspaceId={id}
            pageName={"workspace"}
          />
        ),
        hidden: !authorizeUserComponentAccess(props.me, id, "user", [], true),
      },
      {
        name: "Permissions",
        icon: ClipboardDocumentCheckIcon,
        component: <WorkspacePermissions workspaceId={id} />,
        hidden: !authorizeUserComponentAccess(props.me, id, "permission", [], true),
      },
      {
        name: "Layout & Styles",
        icon: PaintBrushIcon,
        component: <WorkspaceLayoutStyles workspaceId={id} />,
        hidden: !authorizeUserComponentAccess(props.me, id, "layout", [], true),
      },
      {
        name: "Settings",
        icon: Cog8ToothIcon,
        component: <WorkspaceSettings workspaceId={id} />,
        hidden: !authorizeUserComponentAccess(props.me, id, "integration", true),
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.me, workspace]);

  useLoadData("get", `/workspaces/${id}`, {}, (res) => setWorkspace(res.data), workspaceLoaded, setWorkspaceLoaded);

  const loadingColors = {
    IDLE: "group-focus:text-transparent",
    WAITING: "group-focus:text-slate-700/50",
    DONE: "group-focus:text-green-700/50",
  };

  // If you change the layout of workspace and then goto the groups tab, then page link automatically changed depend on layout
  useEffect(() => {
    setWorkspaceLoaded(false);
  }, [location])

  return (
    <div className="h-full w-full">
      <div className="flex h-full w-full items-center justify-between mb-4 md:mb-6">
        <div className="relative flex items-center gap-x-2 sm:gap-x-4">
          <BackIndicator />
          {workspace && (
            <>
              <div
                onClick={() => navigate({ pathname: location.pathname, search: "tab=Layout+%26+Styles" })}
                style={{ backgroundColor: workspace.top_bar_color }}
                className="cursor-pointer rounded group w-auto h-12 min-w-10 relative p-2">
                {workspace._id && (
                  <img
                    src={workspace.image_logo?.includes(".s3.") ? workspace.image_logo + "?" + Date.now() : workspace.image_logo?.includes("data:image/") ? workspace.image_logo : baseUrl + workspace.image_logo}
                    alt="Logo"
                    className="min-w-[80px] sm:min-w-0 w-full h-full object-contain"
                    onError={(e) => (e.target.src = `${noLogo}`)}
                  />
                )}
                <div className="group-hover:opacity-80 opacity-0 rounded-full bg-highlightColor transition-all duration-75 absolute -top-4 -right-4 z-20">
                  <CameraIcon className="h-7 w-7 p-1 text-white" />
                </div>
              </div>
              <button className="group relative">
                <div className={classNames("p-1 font-light py-.5 px-1 flex gap-x-2 items-center overflow-hidden transition-all duration-75 absolute -top-1 -right-7", loadingColors[debounceNameStatus])}>
                  {debounceNameStatus === "WAITING" && (
                    <p className="rounded-full flex bg-gray-500/20 items-center">
                      <ArrowPathIcon className="h-5 w-5 animate-spin text-white" />
                    </p>
                  )}
                  {debounceNameStatus === "DONE" && (
                    <p className="rounded-full bg-green-600/40 text-white flex items-center">
                      <CheckCircleIcon className="h-5 w-5 text-white" />
                    </p>
                  )}
                </div>
                <Input
                  type="text"
                  autoComplete="on"
                  name="workspace-name"
                  value={name}
                  onChange={handleChange}
                  inputClassNames="pr-4 focus:ring-0 focus:border-highlightColor bg-transparent hover:border-slate-400/20 border border-transparent flex items-center gap-x-2 text-3xl px-2 py-1 rounded transition-all duration-75 font-thin text-gray-400"
                />
              </button>
              <div className="hidden sm:flex items-center gap-x-4">
                {tagList.length > 0 && (
                  <div className="flex items-center gap-x-2">
                    {tagList.map((tag, index) => {
                      return (
                        <WorkspaceTag
                          index={index}
                          key={tag}
                          tag={tag}
                          onDelete={removeTag}
                        />
                      );
                    })}
                  </div>
                )}
                <div className="w-40 flex h-8 gap-x-3">
                  <Input
                    type="text"
                    autoComplete="off"
                    name="tag-name"
                    value={tag}
                    onFocus={() => {
                      setFocusedOnTag(true);
                    }}
                    onChange={(e) => setTag(e.target.value)}
                    onBlur={() => {
                      setFocusedOnTag(false);
                      setTag("");
                    }}
                    onKeyDown={handleTag}
                    placeholder="+ Add tag"
                    inputClassNames="focus:ring-0 focus:border-highlightColor bg-transparent hover:border-gray-400/20 border border-transparent flex items-center gap-x-2 text-sm px-2 py-1 rounded transition-all duration-75 text-gray-600 "
                  />
                  {focusedOnTag && (
                    <Button
                      disabled={!tag}
                      onClick={() => handleTag({ submit: true })}
                      version="secondary">
                      Add
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, { getAuthorizedUser })(WorkspaceEdit);
