import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getOperators } from "src/actions/operator";
import { getUserWorkspaceData } from "src/actions/workspace";
import Accordion from "src/components/Shared/Accordions/Accordion";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import WorkspaceGroupListOldVersion from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupListOldVersion";
import { baseUrl, noLogo } from "src/config/host";

const UserWorkspaceList = ({ ...props }) => {
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    const loadData = async () => {
      const getWorkspaces = await props.getUserWorkspaceData({ user_id: id });
      setWorkspaces(getWorkspaces);
      setLoaded(true);
    };
    loadData();
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loaded ? (
        workspaces.length > 0 && (
          <div className="relative grid gap-y-4 bg-white rounded-xl">
            {workspaces.map((workspace) => (
              <Accordion
                key={workspace._id}
                headerContent={() => (
                  <div className="p-2 flex gap-4 items-center">
                    <div className="w-12 h-12 min-w-[48px] min-h-[48px] overflow-hidden rounded">
                      <img
                        src={workspace.image_favicon?.includes(".s3.") ? workspace.image_favicon : workspace.image_favicon?.includes("data:image/") ? workspace.image_favicon : baseUrl + workspace.image_favicon}
                        alt="Logo"
                        className="w-full h-full object-contain"
                        onError={(e) => (e.target.src = `${noLogo}`)}
                      />
                    </div>
                    <div className="text-gray-900 font-medium">{workspace?.name}</div>
                  </div>
                )}>
                <div className="px-4 sm:px-8 pb-24 sm:pb-8">
                  <WorkspaceGroupListOldVersion
                    pageName="user"
                    workspaceId={workspace._id}
                    userId={id}
                    viewUsers={false}
                    viewOnly={true}
                  />
                </div>
              </Accordion>
            ))}
          </div>
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getUserWorkspaceData, getOperators })(UserWorkspaceList);
