import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { managePageData, removePage, setPageData } from "src/actions/page";
import PageEntry from "src/components/Pages/PageEntry";
import CreateDomoPageContent from "src/components/Pages/PageModelContent/CreateDomoPageContent";
import CreateLandingPageContent from "src/components/Pages/PageModelContent/LandingPages/CreateLandingPageContent";
import AddPageDropdown from "src/components/Shared/Buttons/AddPageDropdown";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { validate as uuidValidate } from "uuid";

import ReactHtmlParser from "react-html-parser";
import { getPreviousPageDetails } from "src/actions/page";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject";
import NoData from "src/components/Shared/LoadingAnimations/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import useFetch from "src/hooks/useFetch";
import LandingPage from "src/pages/portal/landing-page";

const PageList = ({ isGlobal = false, workspaceId = "", ...props }) => {
  const [createModal, setCreateModal] = useState(false);
  const [prevModal, setPrevModal] = useState(false);
  const [advancedFeatures, setAdvancedFeatures] = useState(false);
  const [successLoaderStart, setSuccessLoaderStart] = useState(false);
  const [startIndex, setStartIndex] = useState(-1);
  const [deleteId, setDeleteId] = useState(null);
  const [filteredPages, setFilteredPages] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [workspaceFilter] = useState("");

  const [srcDoc, setSrcDoc] = useState("");
  const [noPage, setNoPage] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [navigationBlocks, setNavigationBlocks] = useState([]);

  const jsonArr = {
    _id: "",
    name: "",
    page_type: "DOMO",
    embed_type: "EMBED_PAGE",
    credential_id: null,
    embed_id: "",
    custom_html: "",
    workspace_id: workspaceId || null,
    is_global: isGlobal,
    navigation_blocks: [
      {
        title: "",
        image: "",
        height: "",
        width: "",
        links: [
          {
            link_text: "",
            page_id: null,
          },
        ],
      },
    ],
  };

  const [newPage, setNewPage] = useState(jsonArr);

  const [pageCredentialOptions, setPageCredentialOptions] = useState([]);
  const [navigationPageList, setNavigationPageList] = useState([]);

  // Pagination
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setSelectedWorkspace(updatedSelectedWorkspaces);
  };

  useEffect(() => {
    handlePaginationChange(10, 0);
    refreshPages();
    refreshPages2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobal]);

  useEffect(() => {
    handlePaginationChange(10, 0);
    refreshPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    refreshPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  let pagesFetchOptions = [];
  if (workspaceId) {
    pagesFetchOptions = [`/workspaces/${workspaceId}/pages`, { query: { sort: "ASC", limit, offset, keyword } }];
  } else {
    pagesFetchOptions = ["/pages/list", { method: "post", data: { limit, offset, keyword, is_global: isGlobal } }];
  }

  let pagesFetchOptions2 = [];
  if (workspaceId) {
    pagesFetchOptions2 = [`/workspaces/${workspaceId}/pages`, { query: { sort: "ASC" } }];
  } else {
    pagesFetchOptions2 = ["/pages/list", { method: "post", data: { is_global: isGlobal } }];
  }

  const {
    response: { data: pages, meta },
    status: { done: pagesLoaded },
    refreshData: refreshPages,
  } = useFetch(...pagesFetchOptions);

  const {
    response: { data: allPages },
    refreshData: refreshPages2,
  } = useFetch(...pagesFetchOptions2);

  let credentialFetchOptions = [];
  if (props.user?.type === "admin") {
    credentialFetchOptions = [`/credentials/all`, {}];
  } else if (workspaceId) {
    credentialFetchOptions = [`/workspaces/${workspaceId}/credentials`, {}];
  }

  const {
    response: { data: editPageCredentialOptions },
    refreshData: refreshCredentials,
  } = useFetch(...credentialFetchOptions);

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post" });

  useEffect(() => {
    refreshCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    let filtered = pages;
    if (workspaceFilter) {
      filtered = filtered.filter((page) => {
        return page?.workspace_id._id === workspaceFilter;
      });
    }
    if (!isGlobal) {
      filtered?.sort(function (a, b) {
        return a.workspace_id?.name.localeCompare(b.workspace_id?.name);
      });
    }
    setFilteredPages(filtered ? filtered : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceFilter, pages]);

  const createNewPage = async () => {
    try {
      setSuccessLoaderStart(true);
      let page = {
        is_global: newPage.is_global,
        workspace_id: newPage.workspace_id ? [newPage.workspace_id] : selectedWorkspace,
        credential_id: newPage.credential_id,
        page_type: newPage.page_type,
        custom_html: newPage.custom_html,
        created_at: "",
        created_by: props.user._id,
        deleted_at: null,
        deleted_by: null,
        embed_id: newPage.embed_id,
        embed_type: newPage.embed_type,
        name: newPage.name,
        navigation_blocks: newPage.navigation_blocks,
        updated_at: "",
        updated_by: props.user._id,
        _id: newPage._id || null,
      };

      const { message } = await props.managePageData({
        pages: [page],
      });
      setSuccessLoaderStart(false);
      refreshPages();
      refreshPages2();
      setCreateModal(false);
      setAdvancedFeatures(false);
      toast.success(message);
      setNewPage({
        _id: "",
        name: "",
        page_type: "DOMO",
        embed_type: "EMBED_CARD",
        credential_id: null,
        embed_id: "",
        custom_html: "",
        workspace_id: workspaceId || null,
        navigation_blocks: [
          {
            title: "",
            image: "",
            height: "",
            width: "",
            links: [
              {
                link_text: "",
                page_id: null,
              },
            ],
          },
        ],
      });
    } catch (error) {
      setSuccessLoaderStart(false);
      toast.error(error.message);
    }
  };

  const deletePage = async () => {
    if (deleteId && !uuidValidate(deleteId)) {
      try {
        await props.removePage(deleteId);
        setDeleteId(null);
        refreshPages();
        refreshPages2();
      } catch (error) {
        console.dir(error);
      }
    }
  };

  const editPage = async (page) => {
    if (page.workspace_id.length > 0) {
      let workspace_id = [];
      for (const workspaceid of page.workspace_id) {
        workspace_id.push(workspaceid._id);
      }
      setSelectedWorkspace(workspace_id);
      setNewPage({ ...page, workspace_id: "", is_global: true });
    } else {
      setSelectedWorkspace([]);
      setNewPage({ ...page, workspace_id: page.workspace_id?._id, is_global: false });
    }
    setCreateModal(true);
  };

  const addPage = async (pageType) => {
    setSelectedWorkspace([]);
    setNewPage({ ...jsonArr, page_type: pageType });
    setCreateModal(true);
  };

  useEffect(() => {
    if (newPage && newPage.page_type === "LANDING_PAGE") {
      console.log(newPage)
      let navigationPage = [];
      if (newPage.is_global) {
        // eslint-disable-next-line array-callback-return
        allPages.map((allp) => {
          if (allp._id !== newPage._id && allp.workspace_id.length > 0) {
            navigationPage.push({ value: allp._id, key: allp.name });
          }
        });
      } else {
        // eslint-disable-next-line array-callback-return
        allPages.map((allp) => {
          if (allp._id !== newPage._id && allp.workspace_id?._id === newPage.workspace_id) {
            navigationPage.push({ value: allp._id, key: allp.name });
          }
        });
      }
      setNavigationPageList(navigationPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?._id, newPage?.page_type, allPages]);

  const pagePrev = async (page) => {
    setLoadingPage(true);
    setNoPage(true);
    setSrcDoc("");
    setNavigationBlocks([]);
    setPrevModal(true);
    getPage(page._id);
  };

  const getPage = async (pageId) => {
    let res = await getPreviousPageDetails({ user_id: props?.user?._id, id: pageId });
    setLoadingPage(false);
    if (res.status === 400) {
      setNoPage(true);
      return;
    }
    setNoPage(false);
    if (res.page_type === "CUSTOM_HTML") {
      setSrcDoc(res.custom_html);
      setNavigationBlocks([]);
    } else if (res.page_type === "LANDING_PAGE") {
      setNavigationBlocks(res.navigation_blocks);
      setSrcDoc("");
    } else {
      let { embedToken, embedUrl } = res;

      let htmlIframe = `<iframe id="iframe" title="page-embed" src="" width="100%" marginHeight="0" marginWidth="0" frameBorder="0" srcDoc='<html>
      <body>
        <form id="form" action="${embedUrl}" method="post">
          <input type="hidden" name="embedToken" value="${embedToken}" />
        </form>
      </body>
      <script>document.getElementById("form").submit();</script>
    </html>'></iframe>`;

      setSrcDoc(htmlIframe);
      setNavigationBlocks([]);
    }
  };

  useEffect(() => {
    let creds = [];
    // eslint-disable-next-line array-callback-return
    editPageCredentialOptions?.map((cred) => {
      let insert = false;
      if (cred.workspace_id && newPage?.workspace_id === cred.workspace_id) {
        insert = true;
      }
      if (props.user?.type === "admin" && cred.is_global) {
        insert = true;
      }
      if (insert === true && creds.findIndex((cr) => cr.value === cred._id) === -1) {
        creds.push({ value: cred._id, key: cred.name });
      }
    });
    setPageCredentialOptions(creds);
  }, [editPageCredentialOptions, newPage?.workspace_id, props.user]);

  useEffect(() => {
    setStartIndex(pageCredentialOptions.findIndex((pageCredentialOption) => pageCredentialOption.value === newPage.credential_id));
  }, [pageCredentialOptions, newPage]);

  useEffect(() => {
    let index = pageCredentialOptions.findIndex((pageCredentialOption) => pageCredentialOption.value === newPage.credential_id);
    if (index === -1) {
      setNewPage({ ...newPage, credential_id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCredentialOptions]);

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        rightContent={
          ((workspaceId && authorizeUserComponentAccess(props.user, workspaceId, "page", ["create"])) || (props.user?.type === "admin" && isGlobal)) && (
            <div className="flex items-center justify-between relative z-10">
              <div className="relative flex min-w-[140px] items-center justify-end gap-x-1 ml-auto">
                <AddPageDropdown addPage={addPage} />
              </div>
            </div>
          )
        }></TableOptions>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-l-xl overflow-hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Page Name
            </th>
            <th
              scope="col"
              className="hidden sm:table-cell overflow-hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Type
            </th>
            <th
              scope="col"
              className="rounded-r-xl overflow-hidden  hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          </TRHeader>
        }
        colSpan="5"
        loaded={pagesLoaded}
        dataExists={filteredPages?.length > 0}>
        {filteredPages.map((page, i) => {
          return (
            <Fragment key={page._id + "_" + i}>
              {!workspaceId && !isGlobal && page?.workspace_id?.name !== filteredPages[i - 1]?.workspace_id?.name && (
                <tr>
                  <td
                    colSpan={3}
                    className="break-all py-4 px-4 sm:pl-6 sm:py-3 text-sm font-semibold text-gray-500 whitespace-pre-wrap bg-gray-100">
                    {page?.workspace_id?.name}
                  </td>
                </tr>
              )}
              <PageEntry
                pageCredentialOptions={editPageCredentialOptions}
                workspaceId={page.workspace_id?._id || page.workspace_id}
                key={page._id + "_" + i}
                page={page}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                reloadPagesData={() => refreshPages()}
                editPage={editPage}
                pagePrev={pagePrev}
                is_global={isGlobal}
              />
            </Fragment>
          );
        })}
      </Table>
      <PaginationFooter
        itemName="Pages"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <Modal
        title="Page"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={deletePage}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {pages && pages.find((item) => item._id === deleteId)?.name}?</div>
      </Modal>
      <Modal
        title="Page"
        secondaryTitle="Create"
        isOpen={createModal}
        onCancel={() => setCreateModal(false)}
        onSuccess={createNewPage}
        defaultOptions={{
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}
        size={newPage.page_type === "LANDING_PAGE" ? "xl" : "md"}>
        <div className="grid gap-y-8 whitespace-nowrap px-2 text-sm text-gray-500">
          <div className="mb-2 grid gap-y-3 px-4">
            <div className="my-5 grid space-y-2">
              <Input
                type="text"
                name="page-name"
                label="Name"
                value={newPage.name}
                onChange={(e) => setNewPage({ ...newPage, name: e.target.value })}
              />
              {(newPage?.workspace_id?.page?.is_global_template || isGlobal) && (
                <MultiSelectObject
                  defaultOptions={
                    workspacesLoaded &&
                    workspaces.map((wrk) => {
                      return { key: wrk._id, value: wrk.name, selected: selectedWorkspace.includes(wrk._id), object: wrk };
                    })
                  }
                  searchableFields={["name"]}
                  title="Assign workspaces"
                  onChange={handleSelectedWorkspaces}
                />
              )}
            </div>
            <div className="relative">
              {newPage.page_type === "CUSTOM_HTML" && (
                <TextArea
                  label="Custom HTML"
                  value={newPage.custom_html}
                  onChange={(e) => setNewPage({ ...newPage, custom_html: e.target.value })}
                  placeholder="<iframe> ... </iframe>"
                  textType="code"
                />
              )}

              {newPage.page_type === "DOMO" && (
                <CreateDomoPageContent
                  newPage={newPage}
                  setNewPage={setNewPage}
                  startIndex={startIndex}
                  pageCredentialOptions={pageCredentialOptions}
                  advancedFeatures={advancedFeatures}
                  setAdvancedFeatures={setAdvancedFeatures}
                />
              )}

              {newPage.page_type === "LANDING_PAGE" && (
                <CreateLandingPageContent
                  navigationPageList={navigationPageList}
                  newPage={newPage}
                  setNewPage={setNewPage}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Page"
        secondaryTitle="Preview"
        isOpen={prevModal}
        onCancel={() => setPrevModal(false)}
        size="xxxxxl"
        defaultOptions={{
          onCancelButtonVisible: false,
          onSuccessButtonVisible: false,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap px-2 text-sm text-gray-500">
          <div className="mb-6 grid gap-y-3 px-4">
            <div className="h-full w-full flex min-h-[calc(100vh-144px)]">
              {!loadingPage && !noPage && (srcDoc || navigationBlocks.length > 0) ? (
                srcDoc ? (
                  ReactHtmlParser(srcDoc)
                ) : (
                  <LandingPage
                    from={"previous"}
                    navigationBlocks={navigationBlocks}
                  />
                )
              ) : (
                <div className="w-full h-full flex justify-center mt-20">{loadingPage ? <Preloader /> : !loadingPage && noPage && <NoData />}</div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  managePageData,
  removePage,
  setPageData,
})(PageList);
